.pagination_row{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.button{
    border: none;
    margin-right: 10px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #000;
    padding: 8px;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    
}

.button:disabled .icon{
    filter: invert(40%);
}


.icon{
    width: 20px;
    padding: 0 !important;
    height: auto;
    display: block;
}
.bgGrey{
    background-color: #dedede;
    border-radius: 5px;
    width: 35px;
    height: 35px;
}
.active{
    background: #084298;
    color: #fff;
}