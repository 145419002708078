.stbc-c{}
.pd-tab-btn{}
.tab-active-pdesc{
    background: #C6EEFF;
}
 .pdesc-container {
    padding: 20px;
    width: 88%;
    margin-inline: auto;
}
.pdesc-container .seller-tab-btn-container {
    border-bottom: 1.8px solid rgb(78, 77, 77);
    margin-inline: auto;
}
.pdesc-container .w8-div{
    margin-top: 20px;
}
.pdesc-container .seller-content-container {
 width: 100%;
}
.pdesc-container .seller-tab-btn {
    width: 25%;
    border-bottom: 0px solid white;
}
.pdesc-container .stbc-c {
    width: 100%;
}

@media screen and (max-width:1100px){
    .pdesc-container {
        width: 90%;
        margin-inline: auto;
    }

    .pdesc-container .spl-div{
        flex-direction: column;
    }

}

@media screen and (max-width:500px){
    .pdesc-container {
        width: 100%;
        margin-inline: auto;
    }
}