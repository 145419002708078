@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* .buyers-sidebar {
  width: 90%;
  position: sticky;
  top: 104px;
  left: 0;
  z-index: 999;
color: white;
} */

/* .buyers-sidebar {
  position: fixed;
  top: 104px;
  z-index: 999;
  color: white;
  height: 81vh;
  width: 14%;
} */

.buyers-sidebar {
  position: fixed;
  top: 104px;
  z-index: 999;
  color: white;
  height: 81vh;
  width: 23%;
}

.sts-btn {
  position: absolute;
  bottom: 0;
  left: 0;
}

.buyers-sidebar-heading {
  font-size: 24px;
  padding-left: 15px;
  font-weight: 500;
  margin-bottom: 25px;
}
.css-113qkos-MuiSwitch-root .MuiSwitch-thumb {
  width: 19px;
  height: 19px;
}
.css-113qkos-MuiSwitch-root {
  width: 46px;
  height: 22px;
}

.buyers-options {
  font-size: 15px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  /* height: 50px; */
  height: 40px;
  display: flex;
  align-items: center;
  /* padding-left: 15px; */
  padding-left: 8px;
  border-radius: 10px;
  margin-bottom: 6px;
  cursor: pointer;
  color: white;
  text-decoration: none;
}
.hideSubMenu a {
  color: #000;
}
.buyers-options.active-opn {
  background: white;
  color: #006ff2;
}

.hideSubMenu {
  background: #a2d2ff;
  border-radius: 10px;
  /* margin-left: 10px; */
  display: none;
}

.callbtn {
  position: absolute;
  top: 102px;
  right: -50px;
  background: none;
  border: 2px solid;
  width: 50px;
  height: 50px;
  border-radius: 0 11px 11px 0;
  background: white;
  border: 2px solid #006ff2;
  color: #006ff2;
  background: transparent;
  cursor: pointer;
  display: none;
}
.css-j204z7-MuiFormControlLabel-root {
  margin-left: 44% !important;
}
/* .vrn {
  background: #a2d2ff;
  color: #000;
  float: left;
  width: 270px;
  position: fixed;
  bottom: 0;
  height: 50px;
  left: 0px;
} */
.vrn .css-113qkos-MuiSwitch-root{    width: 3rem; margin-top: 4px;
  height: 1.5rem;}
  .vrn .css-113qkos-MuiSwitch-root .MuiSwitch-thumb{width: 1rem;
    height: 1rem; background-color: lightgray;}
    .vrn .css-113qkos-MuiSwitch-root .MuiSwitch-track{background-color: #fff; border: 1px solid rgba(0,0,0,.25);}
    .vrn .css-113qkos-MuiSwitch-root .MuiSwitch-switchBase{margin: 4px;}
.vrn {
  background: #042d5f;
  color: #fff;
  float: left; 
  font-family: 'Inter', sans-serif;
  width: 25%;
  position: fixed;
  bottom: 20px;
  height: 40px;
  left: 0px;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
}
/* .vrn .css-113qkos-MuiSwitch-root{left: 52%;} */
.vrn label > span {
  /* left: 91%; */
  /* left: 85%; */
}
.vrn label {
  margin-left: 10% !important;
}
.callbtn-i {
  transition: transfrom 0.4s ease;
}
.callbtn.active-callbtn .callbtn-i {
  transform: rotate(133deg);
}

@media screen and (max-width: 700px) {
  .callbtn {
    display: block;
  }

  .buyers-sidebar {
    width: 100%;
    position: fixed;
    top: 77px;
    left: -250px;
    z-index: 997;
    background: white;
    height: 100%;
    box-shadow: 0px -5px 6px rgb(0 0 0 / 50%);
    width: 228px;
    height: 100%;
    padding: 10px;
    transition: 0.2s ease;
    color: black;
  }
  .buyers-options {
    color: rgb(0, 0, 0);
  }
  .buyers-options.active-opn {
    background: #006ff2;
    color: white;
  }
}

.hehe-form {
  margin-right: 0 !important;
  display: flex !important;
  flex-direction: row-reverse !important;
  align-items: center !important;
  width: fit-content !important;
  padding-left: 9px !important;
  /* margin-top: 70px !important; */
}

.hehe-form
  .css-113qkos-MuiSwitch-root
  .MuiSwitch-switchBase.Mui-checked
  + .MuiSwitch-track {
  background-color: #006ff2;
}

@media screen and (max-width: 700px) {
  .hehe-form {
    padding-left: 29px !important;
  }
}

.hideSubMenu:has(> a.active-opn) {
  display: block !important;
}
.buyers-options.active-opn + #MyAccount82 {
  display: block;
}
.menuAllIcons {
  float: left;
  margin-right: 10px;
  height: 23px;
}
.icon4blueShowHide {
  display: none;
}
.buyers-options.active-opn > .icon4blueShowHide {
  display: block;
}
.buyers-options.active-opn > .icon4WhiteShowHide {
  display: none;
}
