.product-card {
  width: 260px;
  padding: 15px 20px;
  background: #ffffff;
  border-radius: 13px;
  box-shadow: 0px 4px 11px rgb(0 0 0 / 25%);
  position: relative;
  margin-bottom: 23px;
}
.product-card .product-img img {
  margin: auto;
  display: block;
  height: 200px;
  width: 100%;
  object-fit: contain;
  aspect-ratio: 3 / 1;
}
.product-card .product-name {
  font-size: 17px;
  margin: 15px 0;
  font-weight: 500;
  color: black;
  text-decoration: none;
  display: block;
  cursor: pointer;
}
.product-card .product-loc {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.product-loc-child {
  font-size: 13px;
  font-weight: 500;
  color: gray;
}
.product-price {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}
.product-price-child1 {
  font-weight: 500;
  font-size: 25px;
  display: flex;
  align-items: center;
}
.product-card .product-button {
  width: 100%;
  height: 36px;
  font-weight: 600;
  margin-top: 9px;
  border-radius: 10px;
  border: none;
  color: white;
  font-size: 14px;
  /* box-shadow: 0px 27px 25px rgb(0 0 0 / 25%); */
  background: #006ff2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.accordian .accordian-btn svg {
  display: none;
}
/* .product-card {
  width: 222px;
  padding: 15px 20px;
  background: #ffffff;
  border-radius: 13px;
  box-shadow: 0px 4px 11px rgb(0 0 0 / 25%);
  margin-bottom: 22px;
  margin-bottom: 22px;
} */

.product-card .product-name {
  font-size: 17px;
  margin: 15px 0;
  font-weight: 500;
  height: 40px;
}

.i-btn {
  margin-left: 5px;
  font-size: 9px;
  display: inline-flex;
  width: 10px;
  justify-content: center;
  height: 10px;
  align-items: center;
  background: none;
  border-radius: 50%;
  border: 1px solid #006ff2;
  color: #0381b7;
  position: relative;
}

.hover-box {
  position: absolute;
  width: 161px;
  left: 5px;
  top: 19px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  color: black;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 25%);
  display: none;
  z-index: 9;
}

.hover-box::before {
  content: "";
  width: 15px;
  height: 15px;
  position: absolute;
  background-color: white;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 25%);
  top: -9px;
  left: 0px;
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
}

.hover-box strong {
  font-size: 14px;
}
.hover-box p {
  font-size: 11px;
}
.hover-box .underb {
  font-size: 10px;
  color: #006ff2;
  text-decoration: underline;
  cursor: pointer;
}

.i-btn:hover .hover-box {
  display: block;
}

.verified-tag {
  position: absolute;
  top: 0;
  background: #006ff2;
  right: 0;
  font-size: 11px;
  font-weight: 500;
  display: flex;
  padding: 5px;
  border-radius: 4px;
  color: white;
  align-items: center;
}
.verified-tag img {
  margin-right: 5px;
}

@media screen and (max-width: 550px) {
  .product-card {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px 20px;
  }
  .product-card .product-button {
    display: none;
  }
  .product-card .product-img img {
    width: 80%;
  }
  .hover-box {
    width: 113px;
  }
}
