.nav-list {
  font-size: 15px;
  transition: 0.2s ease;
  padding: 10px 10px !important;
  text-decoration: none;
  display: block;
  color: rgba(0, 0, 0, 0.87) !important;
  border-radius: 6px;
}
#basic-button {
  position: relative;
}
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper,
.css-177ic5c {
  /* top: 76px; */
  /* left: 1212px !important; */
  /* transform-origin: 30.9844px 0px !important; */
  width: 135px !important;
  padding: 0 10px !important;
  top: 82px !important;
}
.nav-list:hover {
  color: white !important;
  background-color: #006ff2 !important;
}

.dashboard-nav {
  padding: 15px 20px;
  display: flex;
  /* width: 100%; */
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 4px 7px rgb(0 0 0 / 12%);
  /* position: relative; */
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
}

.searchResultContainer {
  position: absolute;
  top: calc(100% + 15px);
  left: 0;
  width: 100%;
  height: fit-content;
  max-height: 400px;
  border: 0.3px solid #ababab;
  background-color: #ffffff;
  padding: 10px 5px;
}

.dashboard-nav .nav-brand {
  font-size: 27px;
  font-weight: 700;
  text-decoration: none;
  color: #006ff2;
  display: flex;
  align-items: center;
}
img.rklogo {
  width: 33px;
  margin-right: 4px;
  margin-bottom: 11px;
}

.dashboard-nav .nav-brand span {
  margin-right: 5px;
  color: #101111;
}
.dashboard-nav .nav-searchbox {
  display: flex;
  position: relative;
  flex-grow: 0.7;
}
.dashboard-nav .nav-searchbox input {
  /* width: 450px; */
  /* width: 667px; */
  border: none;
  background: #f3f3f3;
  border-radius: 7px;
  outline: none;
  padding-left: 10px;
}
.dashboard-nav .nav-searchbox button {
  border: none;
  border-radius: 6px;
  padding: 5px 11px;
  color: white;
  background: #006ff2;
  position: relative;
  left: -12px;
}
.dashboard-nav .nav-spans span,
.dashboard-nav .nav-spans a {
  font-weight: 400;
  margin: 0 21px;
  font-size: 15px;
  text-decoration: none;
  color: black;
}
/* .dashboard-nav .nav-dropdown {
} */
.dashboard-nav .nav-dropdown button {
  padding: 5px;
  border-radius: 6px;
  border: none;
  position: relative;
}

.vs-img {
  position: absolute;
  width: 13px;
  bottom: 5px;
  left: 19px;
}

.nav-slider-btn {
  display: none;
}

.nav-corner {
  display: flex;
  align-items: center;
}
.nav-corner .nav-slider-btn {
  margin-left: 5px;
}

.bag-icon {
  display: none !important;
}

@media screen and (max-width: 1200px) {
  .dashboard-nav .nav-searchbox input {
    width: 350px;
  }
  .nav-slider-btn {
    display: block;
  }
  .nav-sidebar {
    display: flex !important;
  }
  .nav-sidebar .nav-searchbox,
  .nav-sidebar .nav-spans {
    display: flex !important;
  }
}



@media screen and (max-width: 1000px) {
  .nav-searchbox,
  .nav-spans {
    display: none !important;
  }

  #basic-button {
    background: transparent;
    position: relative;
  }

  #basic-button .basic-menu-dd {
    display: none;
  }

  .dashboard-nav .nav-brand {
    font-size: 22px;
    margin-left: 10px;
  }
  img.rklogo {
    width: 23px;
    margin-right: 2px;
    margin-bottom: 5px;
  }

  .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper,
  .css-177ic5c {
    width: 140px !important;
    padding: 0 10px !important;
    top: 68px !important;
  }
  .bag-icon {
    display: block !important;
    color: #006ff2;
    margin-right: 10px;
  }
}

.basic-button-1 {
  display: none;
}

@media screen and (min-width: 1000px) {
  .basic-button-2 {
    display: none;
  }
  .basic-button-1 {
    display: inline-flex;
  }

  .dashboard-nav .nav-searchbox input {
    width: 100%;
  }
}

.css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root,
.css-1sucic7 {
  position: fixed;
  z-index: 999999 !important;
}