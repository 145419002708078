.address-container.active {
  border-color: #006ff2 !important;
  border-width: 2px !important;
  color: #006ff2 !important;
  background-color: #0071f222;
}

.address-container.active .default-btn {
  background-color: #006ff2;
  color: #fff;
}
.address-container .default-btn {
  background-color: #fff;
  border: 1px solid #006ff2;
  color: #006ff2;
  outline: none;
  font-size: 12px;
  border-radius: 10px;
  padding: 3px 12px;
}

.add-address-btn {
  position: fixed;
  bottom: 10px;
  right: 20px;
  background-color: #0071f200;
  border: none;
  border-radius: 50%;
}

.add-address-btn img {
  width: 50px;
  height: 50px;
}

address {
  margin: 0;
}

.action-btn-container button {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
