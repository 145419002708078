.radiobox{
    margin-top: 25px;
}
.radiobox .radio-heading {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 35px;
}
.radiobox .radio-con .rad-box {
    align-items: baseline;
    width: 40%;
}
/* .radiobox .radio-con .rad-box input {
    margin-right: 17px;
    margin-top: -14px;
} */
.radiobox .radio-con .rad-box label {
    font-weight: 500;
    font-size: 15px;
    height: 16px;
    color: #7b7777;
}

.block-label {
    border: 1px solid #006ff2;
    width: 19px;
    height: 17px;
    padding: 0;
    margin: 0;
    margin-right: 8px;
    position: relative;
    top: 2px;
    border-radius: 3px;
}

.radiobox .radio-con .rad-box p {
    font-size: 13px;
    font-weight: 400;
}

@media screen and (max-width:700px){
    .radiobox .radio-con .rad-box {
        align-items: baseline;
        width: 100%;
        margin-bottom: 20px;
    }
    
    
    .radio-con{
        flex-direction: column;
    }

}



.sno{
    justify-content: center;
    width: 30%;
    text-align: center;
    font-weight: 500;
    border-radius: 5px;
    height: 31px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    font-family: inherit;
}