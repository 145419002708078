/*************************************  NAVBAR  *****************************************************/
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css");
.navbar {
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px 20px;
  z-index: 9999;
  position: relative; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 10px 30px 20px; */
  padding: 10px 30px 0px;
  z-index: 9999;
  position: relative;
  box-shadow: 0px 4px 7px rgb(0 0 0 / 12%);
  background: #fff;
}
.logo {
  font-size: 30px;
  font-weight: 700;
  color: #006ff2;
  display: flex;
  align-items: center;
}
.logo img {
  width: 35px;
  margin-right: 5px;
  margin-bottom: 9px;
}
.logo span {
  color: black;
  margin-right: 5px;
}
.navbar .menu {
}
.navbar .menu ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}
.navbar .menu ul .nav-links {
  /*margin: 0 20px; */
  font-size: 15px;
  font-weight: 400;
  color: #02476f;
  padding: 14px 20px 26px;
  text-decoration: none;
  border-bottom: 3px solid #fff;
}
.navbar .menu ul .nav-links:hover {
  border-bottom: 3px solid #006ff2;
}
.menu .active {
  border: none;
  border-bottom: 3px solid #006ff2;
}
.navbar .nav-btns {
}
.navbar .nav-btns .navBtn-1 {
  /* margin-left: 10px; */
  font-size: 17px;
  background-color: transparent;
  border: none;
  font-weight: 600;
  color: #02476f;
}
.navbar .nav-btns .navBtn-2 {
  margin-left: 20px;
  font-size: 15px;
  background-color: #006ff2;
  border: none;
  font-weight: 400;
  color: white;
  padding: 8px 22px;
  border-radius: 4px;
}

.toggle-btn {
  display: none;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
}
.toggle-btn .btndiv-1 {
  height: 20px;
  width: 4px;
  background-color: black;
  margin: 0 3px;
  padding: 0;
  border-radius: 10px;
  transform: rotate(-33deg);
}
.toggle-btn .btndiv-2 {
  height: 40px;
  width: 4px;
  background-color: black;
  margin: 0 3px;
  padding: 0;
  border-radius: 10px;
  transform: rotate(-33deg);
}
.toggle-btn .btndiv-3 {
  height: 20px;
  width: 4px;
  background-color: black;
  margin: 0 3px;
  padding: 0;
  border-radius: 10px;
  transform: rotate(-33deg);
}

.LandingImg {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 500px;
  z-index: -1;
}

/* .li-1 {
  position: absolute;
  height: 90%;
  right: 0;
}
.li-2 {
  position: absolute;
  top: 0;
  height: 84%;
  right: 0px;
}
.li-3 {
  position: absolute;
  top: 138px;
  height: 53%;
  right: 0px;
} */

.li-3 {
  position: absolute;
  top: 88px;
  height: 72%;
  right: 0px;
}
.li-2 {
  position: absolute;
  top: 0;
  height: 95%;
  right: 0px;
  width: 138%;
}
.li-1 {
  position: absolute;
  height: 100%;
  right: 0;
  width: 142%;
}

.popup-c {
  position: absolute;
}
.login-signup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: black;
  width: 100%;
  z-index: 9999;
  opacity: 0.8;
  height: 100vh;
  display: none;
}
.login-signup-box {
  position: fixed;
  top: 50%;
  height: 500px;
  overflow: hidden;
  /* width: 800px; */
  display: none;
  margin: auto;
  background: white;
  z-index: 9999999;
  /* border-radius: 17px; */
  border-radius: 5px;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation-name: example;
  animation-duration: 0.9s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
}
.active-popup .login-signup-overlay {
  display: block;
}
.active-popup .login-signup-box {
  display: inline-flex;
}

.login-signup-box .regi-form-right {
  height: 100%;
  overflow-y: scroll;
}
.login-signup-box .regi-form-right.su {
  height: 84%;
  overflow-y: scroll;
}

.login-signup-box .regi-form-right::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.login-signup-box .regi-form-right {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@keyframes example {
  from {
    opacity: 0;
    top: 80%;
  }
  to {
    opacity: 1;
    top: 50%;
  }
}

/*******************************************  SECTION-1  *****************************************************/

section.landing-sec-1 {
  height: 100vh;
  width: 100%;
}
.sec1-div {
  height: 85%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  padding: 10px 30px;
}
.sec1-div h1 {
  font-size: 4rem;
  margin: 0 0 31px 0;
  color: #5a5454;
}
.sec1-div p {
  font-size: 1.3rem;
  color: #888383;
  margin: 0 0 40px 0;
}
.sec1-div button,
.sec1-div a {
  border: none;
  /* background: #02476f; */
  background: #006ff2;
  color: white;
  padding: 14px 30px;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 30px;
  list-style: none;
  cursor: pointer;
}

/*******************************************  SECTION-2  *****************************************************/

.landing-sec-2 {
  padding: 40px 0;
  /* background: #e7e0e0; */
}
.sec2-heading {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  color: #868686;
}
.landing-flexbox {
  display: flex;
  justify-content: center;
  margin: 50px 0 0 0;
  flex-wrap: wrap;
}
.landing-flexbox img {
  filter: grayscale(100%);
  width: 110px;
  margin: 0 10px;
}

/*******************************************  SECTION-3  *****************************************************/
.landing-sec-3 {
  padding: 100px 50px;
}
.row-box {
  display: flex;
  align-items: center;
  margin-bottom: 100px;
}
.row-box .row-box-1 {
  width: 50%;
  padding-left: 5%;
  padding-right: 5%;
}
.row-box .row-box-1 span {
  font-size: 26px;
  font-weight: 600;
  color: #958d86;
}
.row-box .row-box-1 h1 {
  font-size: 3rem;
  margin: 20px 0;
}
.row-box .row-box-1 p {
  font-size: 17px;
  width: 312px;
}
.row-box .row-box-2 {
  width: 50%;
}
.row-box .row-box-2 .bluebox {
  width: 93%;
  height: 362px;
  background: #c2e9ff;
  box-shadow: 57px -60px 74px -16px #d8f0ff;
  margin-left: auto;
}
.row-box.od .row-box-1 {
  order: 2;
  text-align: end;
}
.row-box.od .row-box-1 p {
  margin-left: auto;
}

.row-box.od .row-box-2 {
  order: 1;
}
.row-box.od .row-box-2 .bluebox {
  box-shadow: -56px -60px 74px -16px #d8f0ff;
  margin: 0;
}

/*******************************************  SECTION-4  *****************************************************/

section.landing-sec-4 {
  margin-bottom: 100px;
}

.h-t-h {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
}
.h-t-p {
  font-size: 19px;
  text-align: center;
}

.work-card-container {
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  margin: 40px 0 0;
  flex-wrap: wrap;
}
.work-card {
  width: 239px;
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 10px;
}
.work-card .work-card-img {
  width: 125px;
  height: 125px;
  background: #c2e9ff;
  border-radius: 50%;
}
.work-card .work-card-details {
  margin-top: 23px;
}
.work-card .work-card-details h1 {
}
.work-card .work-card-details p {
}

/*******************************************  SECTION-5  *****************************************************/

section.landing-sec-5 {
  margin-bottom: 100px;
}

.reviews-container {
  display: flex;
  justify-content: center;
  padding: 50px 0 0;
  align-items: baseline;
  flex-wrap: wrap;
}
.review-card {
  width: 350px;
  padding: 20px;
  margin: 0 10px 10px;
  /* box-shadow: 0 0 4px 0 black; */
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  box-shadow: rgb(0 0 0 / 17%) 0px 8px 24px;
  border-radius: 8px;
}
.golden-star {
  color: gold;
}
.review-card p {
  font-size: 15px;
  line-height: 1.6;
  font-weight: 400;
  color: #5e5959;
}

.review-card .reviewer-profile {
  display: flex;
  align-items: center;
  margin-top: 32px;
}

.review-card .reviewer-profile .reviewer-dp {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background: #dad1d1;
  border-radius: 50%;
}

.review-card .reviewer-profile .reviewer-name {
  font-size: 13px;
  font-weight: 500;
  color: gray;
}

.review-date {
  font-size: 13px;
  font-weight: 500;
  color: gray;
  margin-top: 4px;
}

.swiper-backface-hidden .swiper-slide {
  transform: translateZ(0);
  backface-visibility: hidden;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/*******************************************  SECTION-6  *****************************************************/

.flex-row {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 100px 0;
}
.flex-row .flex-50 {
  width: 40%;
}
.flex-50 h1 {
  font-size: 4rem;
}
.flex-50 p {
  font-size: 17px;
  width: 78%;
}

.reach-form {
  display: flex;
  flex-direction: column;
  width: 70%;
  padding: 20px;
  /* box-shadow: 0 0 4px 0 black; */
  box-shadow: rgb(0 0 0 / 17%) 0px 8px 24px;
  border-radius: 12px;
}

.reach-form .form-heading {
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 35px;
}
.reach-form input {
  margin-bottom: 25px;
  padding: 16px 10px;
  border-radius: 4px;
  border: none;
  background: #efefef;
  font-size: 16px;
}
.reach-form textarea {
  margin-bottom: 25px;
  padding: 16px 10px;
  border-radius: 4px;
  border: none;
  background: #efefef;
  font-size: 16px;
  min-height: 100px;
  font-family: sans-serif;
}

.reach-form button {
  font-size: 17px;
  padding: 11px 10px;
  border-radius: 6px;
  font-weight: 600;
  color: white;
  border: none;
  background: #0185cf;
}

/******************************************* SECTION 7  *****************************************************/

.landing-sec-7 {
  padding: 50px 0;
  color: white;
  text-align: center;
  background-color: #0185cf;
}
.landing-sec-7 h1 {
  font-size: 3rem;
  /* margin-bottom: 0px; */
  margin: 0;
}
.landing-sec-7 p {
  width: 80%;
  margin: 20px auto;
}
.landing-sec-7 form {
  margin-top: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.landing-sec-7 form input {
  width: 25%;
  padding: 15px;
  font-size: 16px;
  border-radius: 8px 0 0 8px;
  border: none;
}
.landing-sec-7 form button {
  border: none;
  border-radius: 0 8px 8px 0;
  /* border: none; */
  font-size: 16px;
  padding: 15px;
  width: 124px;
  font-weight: 600;
  color: white;
  background: #055887;
}

/******************************************* FOOTER *****************************************************/

.footer {
  padding: 50px 20px 10px;
  background: #eaeaea;
  clear: both;
}

.upper-footer {
  display: flex;
  width: 100%;
  margin-bottom: 120px;
}
.upper-footer .logodiv {
  width: 40%;
}

.upper-footer .logodiv a {
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 600;
  color: #006ff2;
  text-decoration: none;
}

.upper-footer .logodiv a span {
  margin-right: 5px;
  color: black;
}
.upper-footer .logodiv p {
  width: 70%;
  font-weight: 400;
  color: gray;
}

.upper-footer .ul-f {
  width: 20%;
}
.upper-footer .ul-f ul {
  list-style: none;
}
.upper-footer .ul-f ul .footer-li {
  /* margin-bottom: 20px;
  font-size: 20px; */
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  color: gray;
}
.upper-footer .ul-f ul .footer-li a {
  color: gray;
}
.upper-footer .ul-f ul {
  list-style: none;
  padding-left: 0px;
}
.ul-f h2 {
  font-size: 20px;
  color: #0185cf;
}
.social_icon {
  margin-bottom: 15px;
}
.social_icon img {
  height: 28px;
  width: 28px;
  margin-right: 10px;
}

.bottom-footer p {
  font-size: 13px;
  font-weight: 400;
}

/******************************************* MEDIA QUERY *****************************************************/
/******************************************* MEDIA QUERY *****************************************************/
/******************************************* MEDIA QUERY *****************************************************/

@media screen and (max-width: 1000px) {
  .navbar .menu,
  .navbar .nav-btns {
    display: none;
  }

  .toggle-btn {
    display: flex;
  }

  .active-popup .login-signup-box {
    display: inline-flex;
    width: 260px;
    flex-direction: column-reverse;
  }

  .active-popup .login-signup-box .regi-form-left {
    display: none;
  }

  .login-signup-box .regi-form-right {
    width: fit-content;
  }

  .login-signup-box .regi-form-right.su {
    margin: auto;
  }
  .regi-form-right form .regi-input-c input {
    padding: 6px 0px 6px 10px;
  }
  .regi-form-right.su form .regi-input-c input {
    width: max-content !important;
  }

  .regi-form-right form .regi-input-c.hehe-otp input {
    width: 100% !important;
  }
}

@media screen and (max-width: 750px) {
  .h-t-p {
    font-size: 15px;
    text-align: center;
  }

  .h-t-h {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
  }

  .li-1 {
    position: absolute;
    height: 50%;
    right: 0;
    width: auto;
  }

  .li-2 {
    position: absolute;
    top: 0;
    height: 45%;
    right: 0px;
    width: auto;
  }

  .li-3 {
    position: absolute;
    top: 59px;
    height: 30%;
    right: 0px;
    width: auto;
  }

  /* LANDING-SEC-1 */

  section.landing-sec-1 {
    height: 100vh;
    /* height: 70vh; */
  }
  .sec1-div {
    height: 100%;
  }
  .sec1-div h1 {
    font-size: 2rem;
  }

  .sec1-div p {
    font-size: 1rem;
  }

  .sec1-div button {
    font-size: 0.8rem;
  }

  /* LANDING-SEC-2 */

  /* .landing-sec-2 {
    margin: 40px 0;
  } */
  .landing-sec-2 {
    margin: -45px 0 40px;
    padding: 0 0 20px;
  }

  /* LANDING-SEC-3 */

  .landing-sec-3 {
    padding: 50px 10px 0;
    text-align: center;
  }
  .row-box {
    flex-direction: column;
  }
  .row-box .row-box-1 h1 {
    font-size: 2rem;
    margin: 20px 0;
  }
  .row-box .row-box-1 p {
    font-size: 14px;
    display: block;
    margin-inline: auto;
  }

  .row-box .row-box-1 {
    width: 100%;
  }

  .row-box .row-box-2 {
    width: 100%;
    margin-top: 0px;
  }
  .row-box .row-box-2 .bluebox {
    width: 100%;
    box-shadow: 0px 0 74px -16px #d8f0ff;
    margin-left: unset;
  }

  .row-box .row-box-1 {
    width: 100%;
  }

  .row-box.od .row-box-2 .bluebox {
    box-shadow: 0px 0px 74px -16px #d8f0ff;
    margin: unset;
  }

  .row-box.od .row-box-1 {
    order: 1;
    text-align: center;
  }

  .row-box.od .row-box-2 {
    order: 1;
  }

  section.landing-sec-4 {
    margin-bottom: 0;
  }

  section.landing-sec-5 {
    margin-bottom: 0;
  }

  /* LANDING-SEC-6 */

  #landing-sec-6 .flex-row {
    padding: 50px 0 50px;
    flex-direction: column !important;
  }

  #landing-sec-6 .flex-row .flex-50 {
    width: 100%;
    text-align: center;
  }

  #landing-sec-6 .flex-50 h1 {
    font-size: 1.5rem;
  }

  #landing-sec-6 .flex-50 p {
    font-size: 17px;
    width: 78%;
    margin: 0 auto 20px;
  }

  #landing-sec-6 .reach-form {
    margin: 30px auto 0;
    width: 93%;
  }

  /* LANDING-SEC-7 */

  .landing-sec-7 h1 {
    font-size: 1.5rem;
  }

  .reach-form input {
    width: 54%;
    font-size: 12px;
  }

  .reach-form button {
    font-size: 12px;
    width: 111px;
  }

  /* FOOTER */

  .upper-footer {
    flex-wrap: wrap;
    margin-bottom: 50px;
  }
  .upper-footer .logodiv {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  .upper-footer .logodiv p {
    margin: 10px auto;
  }
  .upper-footer .ul-f {
    width: 50%;
    margin-bottom: 10px;
    text-align: center;
  }
  .upper-footer .ul-f ul {
    list-style: none;
    padding-left: 0;
  }
  .upper-footer .ul-f ul .footer-li {
    font-size: 17px;
  }
  .bottom-footer p {
    text-align: center;
  }
}

.landing-sidebar-ul {
  padding: 50px 0 0 0px;
  list-style: none;
}
.lul-li {
  font-weight: 500;
  margin-bottom: 50px;
  font-size: 19px;
  text-decoration: none;
  color: black;
  cursor: pointer;
  display: block;
}

@media screen and (max-width: 550px) {
  .login-signup-box {
    /* height: 413px; */
  }
  .login-signup-box .regi-form-right {
    width: -moz-fit-content;
    width: 100%;
  }

  .regi-form-right form .button {
    margin-top: 26px !important;
  }
  .logo {
    font-size: 24px;
    font-weight: 700;
  }
  .logo img {
    width: 24px;
  }
  .navbar {
    padding: 10px 15px 20px;
  }
  .li-1 {
    position: absolute;
    height: 45%;
  }
}

/* .sub-popup {
  width: 300px;
  height: 300px !important;
  overflow: hidden;
} */

.work-card .work-card-details h1 {
  width: 160px;
  text-align: center;
  margin-inline: auto;
}

.footer-logo {
  width: 30px;
  margin-right: 4px;
}

@media screen and (max-width: 550px) {
  .row-box .row-box-2 .bluebox {
    height: 222px;
  }
}

@media screen and (max-width: 770px) {
  .upper-footer .logodiv p {
    margin: 10px auto;
    text-align: justify !important;
    width: 245px;
  }
}

.contact-popup svg.popup-i {
  font-size: 23px;
  position: absolute;
  top: 4px;
  right: 13px;
  cursor: pointer;
  /* margin-bottom: 28px; */
}

@media screen and (max-width: 1000px) {
  .contact-popup .reach-form {
    padding: 14px;
  }
  .contact-popup .reach-form input,
  .contact-popup .reach-form button {
    width: auto;
  }
}

.upper-footer {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px solid #c7c7c7;
}

.contactUsOuter {
  background: #006ff2;
  height: 290px;
}
.ConatctleftSec {
  float: left;
  width: 60%;
  margin-top: 100px;
  color: #fff;
  margin-left: 5%;
}
.contactUsInner {
  width: 90%;
  margin: 0px auto;
}
.ContactContentSection {
  /* width: 67%; */
  margin: 0px auto;
  /* height: 3676px; */
}
.ContactContentSection.aboutus {
  width: 82%;
  margin: 0px auto;
  /* min-height: 671vh; */
}
/* .ContactContentSectionLeft{width: 221px; float: left;} */
.ContactContentSectionRight {
  width: 80%;
  margin: 30px auto;
  background-color: #fff;
  border: 1px solid #c7c7c7;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 40px;
}
.contactFormTop {
  display: flex;
  align-items: center;
}
.contactFormTop img {
  height: 50px;
  width: 50px;
}
.contactFormTop p {
  margin-left: 20px;
}
.ContactContentSectionRight form {
  margin-top: 50px;
}
.ContactContentSectionRight input {
  background: #f3f3f5;
  border: 0px;
  width: 45%;
  padding-left: 10px;
  /* float: left; */
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  margin: 10px 2.5%;
}
.ContactContentSectionRight input:focus-visible,
.ContactContentSectionRight textarea {
  border: 0px;
  outline: none;
}
.ContactContentSectionRight textarea {
  padding: 10px;
  width: 94.6%;
  background: #f3f3f5;
  border: 0px;
  height: 140px;
  margin-left: 2.5%;
}
.ContactContentSectionRight p {
  margin-left: 2.5%;
  margin-top: 20px;
}
.landing-sec-2New {
  width: 100%;
}
.ConatctSteps {
  margin: 0px auto;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 10px;
  /* margin-bottom: 20px !important; */
  padding-left: 0px;
}
.ConatctSteps h3 {
  margin: 0px;
  font-size: 17px;
  font-weight: 500;
}
.ConatctSteps p {
  margin: 0px;
  font-size: 15px;
  margin-top: 6px;
}
.ContactContentSectionRight button {
  width: 100%;
  /* height: 40px; */
  /* line-height: 40px; */
  background: #006ff2;
  border: 1px solid #006ff2;
  border-radius: 4px;
  width: 95%;
  margin: 0px auto;
  margin-left: 2.5%;
  /* margin-top: 20px; */
  font-size: 20px;
  color: #fff;
}
.aboutOuterShadow {
  width: 67%;
  margin: 0px auto;
  background: #fff;
  border-radius: 5px;
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
  clear: both;
  margin-top: -44px;
  z-index: 99;
  float: left;
  margin-left: 17.5%;
  box-sizing: border-box;
  padding: 0px 20px;
  padding-bottom: 20px;
  margin-bottom: 30px;
}
.aboutBLueStep {
  background: #006ff2;
  z-index: -1;
  height: 130px;
  clear: both;
  float: left;
  display: block;
  width: 100%;
  text-align: center;
}
.aboutUsSectionOter {
  width: 100%;
  margin: 0px auto;
}
.aboutUsSectionOter h3 {
  font-size: 30px;
  text-align: center;
}
.OurFounderImages {
  width: 100%;
  height: 250px;
  border-radius: 4px;
  float: left;
  background: gray;
}
.FoundersDetails {
  float: left;
  margin: 20px 0px;
  width: 100%;
  margin-left: 10px;
}
.OurFounderImages2 {
  float: right;
}
.FoundersDetails h5 {
  font-size: 24px;
  margin: 0px;
}
.FoundersDetails p {
  font-size: 15px;
  margin: 0px;
  margin-top: 10px;
  font-weight: 500;
}
.FoundersCard {
  width: 31%;
  margin: 10px 1%;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  float: left;
}
@media (max-width: 767px) {
  .reach-form input {
    width: 100%;
  }
  .ConatctleftSec {
    width: 100%;
  }
  .ContactContentSectionRightnew2 {
    box-sizing: border-box;
    padding: 15px !important;
  }
  .ContactContentSection2 {
    width: 90%;
  }
  .aboutOuterShadow {
    width: 94%;
    margin: 0px auto;
    background: #fff;
    border-radius: 5px;
    box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px,
      rgb(60 64 67 / 15%) 0px 1px 3px 1px;
    clear: both;
    margin-top: -44px;
    z-index: 99;
    float: left;
    margin-left: 3%;
    box-sizing: border-box;
    padding: 0px 20px;
    padding-bottom: 20px;
    margin-bottom: 30px;
  }

  .FoundersCard {
    width: 48%;
    margin: 20px 1%;
    float: left;
  }
  .FoundersCard2 {
    display: flex;
    flex-direction: column-reverse;
  }
  .OurFounderImages2 {
    float: left;
  }
  .aboutUsSectionOter {
    width: 90%;
  }
  .ContactContentSectionLeft {
    width: 100%;
    float: unset;
  }
  .ContactContentSectionRight {
    width: 100%;
    float: unset;
    top: unset;
  }
  .ConatctleftSec {
    top: 20px;
    margin-left: 0px;
  }
  .contactUsOuter {
    /* height: 140px; */
  }
  .ContactContentSectionRight {
    padding: 0px;
    margin-top: 30px;
  }
  .ContactContentSectionRight form {
    margin-top: 20px;
  }
  .ContactContentSectionRight input {
    width: 100%;
    margin: 10px 0px;
  }
  .ContactContentSectionRight textarea {
    width: 100%;
    margin-left: 0px;
  }
  .contactFormTop img {
    height: 50px;
    width: 50px;
  }
  .ContactContentSectionRight button {
    width: 100%;
    margin-left: 0px;
  }
  .contactFormTop p {
    margin-top: 0px;
  }
  .ConatctleftSec {
    margin-top: 0px;
  }
  .ConatctSteps {
    margin-bottom: 0px !important;
    padding-left: 0px;
  }
}

.ContactContentSectionRight.aboutus {
  position: relative;
  right: 0px;
}

.FoundersCardAno {
  display: none;
}
@media screen and (max-width: 760px) {
  .ContactContentSectionRight.aboutus {
    position: relative;
  }

  .FoundersCardAno {
    display: block;
    box-shadow: none;
    margin-bottom: 0px;
  }
  .FoundersCardAno h3 {
    margin-bottom: 0px;
  }
  .HideNameMobil {
    visibility: hidden;
  }
  .ContactContentSection.aboutus {
    width: 94%;
  }
  .ContactContentSection2 {
    width: 90%;
  }
}
@media screen and (max-width: 644px) {
  .landing-sec-7 form input {
    width: 53%;
  }
}
