.popupbox {
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: black;
  width: 100%;
  z-index: 9999;
  opacity: 0.8;
  height: 100vh;
  display: none;
}
.popup-content {
  position: fixed;
  top: 50%;
  width: 430px;
  display: none;
  margin: auto;
  z-index: 9999999;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation-name: example;
  animation-duration: 0.4s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  flex-direction: column;
}

.popup-content > div {
  background-color: white;
  padding: 20px;
  border-radius: 7px;
  margin-bottom: -73px;
  /* height: 287px; */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  overflow-y: scroll;
}
/* .jcc .backbtn-g{width: 200px;} */

.popup-content div::-webkit-scrollbar {
  display: none;
}

svg.popup-i {
  font-size: 23px;
  position: absolute;
  top: 19px;
  right: 17px;
  cursor: pointer;
}

.active-popup .popup-overlay {
  display: block;
}
.active-popup .popup-content {
  display: inline-flex;
}

/* BUYERS RFQ DETAILS POPUP */

.popup-content.brd-popup-div {
  width: max-content;
}
.popup-content.brd-popup-div > div {
  width: 750px;
  height: auto;
}

.body-1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 26px;
}
.body-1 .seller-det-right {
  font-weight: 500;
  color: #959595;
  font-size: 14px;
  width: 86px;
}
.body-1 .seller-det-left {
  font-weight: 500;
  font-size: 13px;
  width: 215px;
}
.seller-det.w5-ds {
  width: 45%;
  display: inline-flex;
  margin-bottom: 21px;
}

.body-2 .input-heading {
  color: #006ff2;
  font-weight: 500 !important;
}

.body-2 {
  border-top: 1.4px solid #d6d6d6;
}

@media screen and (max-width: 600px) {
  .popup-content.brd-popup-div {
    width:90%;
  }
  .popup-content.brd-popup-div > div {
    width: 89%;
    height: fit-content;
  }

  .seller-det.w5-ds {
    width: 100%;
  }

  .popup-content {
    width: 90%;
  }
}
