

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  padding: 0;
  box-sizing: border-box;
}

/* body::-webkit-scrollbar {
  display: none;
} */

/* Hide scrollbar for IE, Edge and Firefox */
/* body {
  -ms-overflow-style: none;  
  scrollbar-width: none;
} */

/* div::-webkit-scrollbar {
  display: none;
}

div {
  -ms-overflow-style: none;  
  scrollbar-width: none;  
} */


