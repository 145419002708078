.checkout-done {
    flex-direction: column;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    text-align: center;
    width: 560px;
    margin: auto;
}

.cd-top{}
.cd-top img{}
.cd-top h1{}
.cd-top p{}
.cd-bottom{}

.cd-bottom .cd-bottom-c {
        /* margin-left: 10px; */
        height: 40px;
        width: 48%;
        border-radius: 6px;
        font-weight: 600;
        color: white;
        border: 2px solid #006FF2;
        cursor: pointer;
        background: #006FF2;
        font-family: inherit;
        text-decoration: none;
        display: inline-grid;
        align-items: center;
        justify-content: center;
        font-size: 13px;
    }


@media screen and (max-width:600px){
    .checkout-done {
        width: 80%;
    }
}




/*Done 2 */

.paymentRefrenceNum{width: 100%;
    float: left;
    text-align: left;}
	.checkout-done h3{font-weight: 400;}
    .paymentRefrenceNum h3{margin-top: 30px; margin-bottom: 10px; font-weight: 400;}
	.refrenceboxSec{display: flex;
		max-height: 40px;}
		.refrencesendBtn{    padding: 0px 20px;
			margin-left: 10px;
			background: #006FF2; font-size: 15px; font-weight: 600;
			color: #fff;
			border: 1px solid #006FF2;
			border-radius: 4px;}
    .paymentRefrenceNum input    {    height: 40px;
        width: 100%;
        border-radius: 5px;
        border: 1px solid #c7c7c7;
        margin-bottom: 30px;}
        .Shop-More{ background-color: #E5F7FF !important; color: #006FF2 !important; border: 1px solid #006FF2 !important;}
        .cd-bottom2 {
            display: flex;
            justify-content: space-between;
            width: 100%;
			margin-top: 50px; margin-bottom: 40px;
        }
.CheckOutPayNowBTN{padding-top: 0px; padding-bottom: 0px;}
.CheckOutPayNowBTN a{height: 40px; width: 100%; display: block; line-height: 40px; color: #fff;}
#CheckOutPayNowBTNTrans{display: none;}
.form-container {
	/* width: 100vw;
	height: 100vh; */
	/* background-color: #7b2cbf; */
	width: 100%;
	display: flex;
	margin-top: 40px;
   	justify-content: center;
	align-items: center;
}
.upload-files-container {
	border: 1px solid #c7c7c7;
	/* background-color: #f7fff7; */
	max-width: 580px;
    width: 100%;
	padding: 20px;
	border-radius: 10px;
	display: flex;
   	align-items: center;
   	justify-content: center;
	flex-direction: column;
	/* box-shadow: rgba(0, 0, 0, 0.24) 0px 10px 20px, rgba(0, 0, 0, 0.28) 0px 6px 6px; */
}
.drag-file-area {
	border: 1px dashed #006FF2;
	border-radius: 10px;
    background-color: #EFF7FF;
	margin: 10px 0 15px;
	padding: 30px 50px;
	width: 100%;
    display: grid;
	text-align: center;
}
.drag-file-area .upload-icon {
	font-size: 50px;
}
.drag-file-area h3 {
	font-size: 26px;
	margin: 15px 0;
}
.drag-file-area label {
	font-size: 19px;
    display: block; margin: 0px auto;
}
.default-file-input81{display: none;}
.drag-file-area label .browse-files-text {
	color: #7E7E7E;
	font-weight: bolder; font-size: 15px;
	cursor: pointer;
}
.browse-files span {
	position: relative;
	top: -25px;
}
.default-file-input {
	opacity: 0;
}
.cannot-upload-message {
	background-color: #ffc6c4;
	font-size: 17px;
	display: flex;
	align-items: center;
	margin: 5px 0;
	padding: 5px 10px 5px 30px;
	border-radius: 5px;
	color: #BB0000;
	display: none;
}
@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
.cannot-upload-message span, .upload-button-icon {
	padding-right: 10px;
}
.cannot-upload-message span:last-child {
	padding-left: 20px;
	cursor: pointer;
}
.file-block {
	color: #f7fff7;
	background-color: #7b2cbf;
  	transition: all 1s;
	width: 390px;
	position: relative;
	display: none;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: 10px 0 15px;
	padding: 10px 20px;
	border-radius: 25px;
	cursor: pointer;
}
.file-info {
	display: flex;
	align-items: center;
	font-size: 15px;
}
.file-icon {
	margin-right: 10px;
}
.file-name, .file-size {
	padding: 0 3px;
}
.remove-file-icon {
	cursor: pointer;
}
.progress-bar {
	display: flex;
	position: absolute;
	bottom: 0;
	left: 4.5%;
	width: 0;
	height: 5px;
	border-radius: 25px;
	background-color: #4BB543;
}
.upload-button {
	font-family: 'Montserrat';
	background-color: #7b2cbf;
	color: #f7fff7;
	display: flex;
	align-items: center;
	font-size: 18px;
	border: none;
	border-radius: 20px;
	margin: 10px;
	padding: 7.5px 50px;
	cursor: pointer;
}