.swiper .product-card{
    margin: auto !important;
}

.swiper{
    padding: 20px 20px 50px !important;
}

@media screen and (max-width:550px){
    /* .swiper .product-card {
        flex-direction: column;
        width: 137px;
    } */
}
@media screen and (max-width:550px){
    /* .swiper .product-card {
        width: 90px;
    }

    .swiper .product-card .product-img img {
        width: 77%;
    }
    .swiper .product-card .product-name {
        font-size: 13px;
        height: 32px;
    } */

    .products-slider .product-card{
width: 95%;
    }
}

