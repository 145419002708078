@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.product-details-container {
}

.pd-divider {
  display: flex;
  width: 100%;
}

.pd-divider .pd-left {
  width: 40%;
  padding: 20px;
  position: sticky;
  top: 74px;
  height: fit-content;
  left: 0;
  z-index: 1;
}

/* .pd-divider .pd-left div{
    background-color: rgb(209, 208, 208);
    width: 100%;
    height: 350px;
} */
.pd-divider .pd-right {
  width: 60%;
  padding: 20px;
}

/* .pd-divider .pd-right .pd-right-details{
     width: 100%;
} */

.h2-heading {
  font-size: 25px;
  text-align: center;
  margin: 50px 0 30px;
}

/*************************************************************************************************************/

.pd-divider .pd-right .pd-right-details {
  width: 90%;
}

.product-name {
  font-size: 35px;
  margin-bottom: 25px;
  font-weight: 500;
}

.product-rate-avail {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.product-availibility {
  font-weight: 600;
  color: rgb(128, 0, 0);
}
.product-availibility.in {
  color: green;
  border:none;
}

.product-rating {
  /* display: flex; */
}

.rating-heading {
  font-weight: 500;
  font-size: 13px;
  padding-left: 3px;
  color: #898c89;
}

.stars-div {
  display: flex;
  align-items: center;
}

.start-b {
  font-size: 21px;
  color: #006ff2;
}

.start-g {
  font-size: 21px;
  color: #aaacac;
}

.ratings-numbers {
  font-weight: 500;
  font-size: 11px;
  padding-left: 3px;
  color: #898c89;
  padding-bottom: 5px;
}

.uni-heading {
  font-weight: 500;
  margin-bottom: 15px;
}

.product-des {
  margin-bottom: 25px;
}

.product-des p {
  font-size: 14px;
}

.product-size {
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: flex-end;
}

.product-size svg {
  transform: translate(-30px, 7px);
}

.product-size select {
  margin-bottom: 15px;
}

.size-dropdown {
  width: 138px;
  padding: 0px 10px;
  background: #f6f6f6;
  border-radius: 7px;
  font-weight: 600;
  border: none;
  outline: none;
  font-family: inherit;
  font-style: inherit;
  height: 40px;
}

.product-qty-pincode {
  display: flex;
  justify-content: space-between;
}

.product-qty-input {
  display: flex;
  border-radius: 7px;
  overflow: hidden;
  align-items: center;
}

.product-qty-input button {
  border: none;
  height: 40px;
  background-color: #f6f6f6;
  color: #434141 !important;
  width: 100px;
}

.product-qty-input button svg {
  font-size: 15px;
}

.product-qty-input .show-qty {
  background: #f6f6f6;
  border: none;
  height: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 65px;
  outline: none;
  font-size: 18px;
  color: #434141;
  align-items: center;
  font-weight: 600;
}

form.product-pincode-input {
  display: flex;
  height: 40px;
}

form.product-pincode-input input {
  border: none;
  background: #f6f6f6;
  border-radius: 5px 0 0 5px;
  outline: none;
  padding: 0 10px;
  font-size: 14px;
}

form.product-pincode-input button {
  border-radius: 0 5px 5px 0;
  border: none;
  background: #f6f6f6;
}

.table-c {
  width: 100%;
  overflow-x: scroll;
}

table.seller-price-table.pdt {
  /* padding-top: 10px; */
  margin-bottom: 25px;
  /* min-width: 694px; */
  min-width: 440px;
  width: 100%;
  margin-top: 30px;
  border-collapse: collapse;
}

.pdt .tablerow-head {
  vertical-align: middle;
  height: 40px;
  font-size: 15px;
  color: #000;
  background: #e7f2ff;
  border-radius: 5px;
}

.table-head {
  text-align: center;
  font-weight: normal;
}

.pdt .tablerow-data {
  height: 50px;
  font-size: 15px;
  font-weight: 500;
}

.inp-td {
  width: 45px !important;
  vertical-align: top;
  height: 26px;
}

.table-data {
  width: 130px;
}

.price-heading {
  font-weight: 500;
  /* margin-bottom: 5px; */
  color: #520000;
}

.ite-price-container {
  margin: 10px 0 20px;
}

span.old-price {
  color: gray;
  text-decoration: line-through;
}

span.price {
  margin-right: 15px;
  font-size: 35px;
  font-weight: 500;
}

span.price-span {
  font-weight: 400;
  color: gray;
  font-size: 13px;
}

.discount-percentage {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #006ff2;
}

.price-btn {
  margin-right: 15px;
  height: 36px;
  width: 50%;
  border-radius: 6px;
  border: none;
  background: #006ff2;
  color: white;
  font-weight: 600;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  font-size: 15px;
}

.price-btn.disabled {
  background: #3a8be8;
  cursor: not-allowed;
}

.pd-right-details .table-c,
.logistics-table-container {
  overflow-x: unset !important;
}

.price-desc {
  margin-bottom: 25px;
}

.logistics-table-container {
  padding-top: 5px;
  margin-bottom: 25px;
  width: 100%;
  overflow-x: scroll;
}

.logistics-table-container table.logistics-table {
  min-width: 695px;
  /* background: red; */
}

@media (max-width: 767px) {
  .logistics-table-container table.logistics-table {
    min-width: 200px;
    overflow-x: scroll;
  }

  table.seller-price-table.pdt {
    min-width: 2px;
  }

  .product-qty-pincode {
    flex-direction: column;
  }

  .product-size {
    width: 100% !important;
  }

  .RadioBTNbutton {
    clear: both;
    float: left;
    margin-bottom: 10px !important;
    width: 100% !important;
  }

  .SelectMl {
    width: 100% !important;
  }

  .product-size .tab1 {
    width: 100% !important;
  }

  .product-qty-input {
    display: flex;
    border-radius: 7px;
    overflow: hidden;
    align-items: center;
    width: 100% !important;
    justify-content: space-between;
    background: #f6f6f6;
  }

  .product-qty {
    width: 100%;
  }
}

tr.logistics-headrow {
  height: 41px;
  background: #c6eeff;
  font-size: 12px;
}

th.logistics-head {
  border-spacing: 0;
  padding: 0 10px;
  text-align: center;
}

td.logistics-data {
  font-size: 12px;
  font-weight: 500;
  height: 30px;
  text-align: center;
}

.dd-box {
  width: 137px;
  border-radius: 6px !important;
  overflow: hidden;
}

.select-size {
  height: 37px;
  background: #f0f0f0;
  font-size: 15px !important;
  font-weight: 500 !important;
  outline: none !important;
}

@media screen and (max-width: 1000px) {
  .pd-divider {
    flex-direction: column;
  }

  .pd-divider .pd-left {
    width: 100%;
    padding: 0px;
    position: unset;
  }

  .pd-divider .pd-right {
    width: 100%;
    padding: 0px;
  }

  .pd-divider .pd-right .pd-right-details {
    width: 90%;
    padding: 15px;
  }

  .product-qty {
    margin-bottom: 25px;
  }

  /* .product-qty-pincode {
    flex-direction: column;
  } */
  .product-size {
    width: 50%;
    flex-direction: column;
    align-items: unset;
  }

  form.product-pincode-input {
    width: 100%;
  }

  form.product-pincode-input input {
    width: 100%;
  }

  .product-pincode {
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .product-name {
    font-size: 25px;
    margin-bottom: 25px;
    font-weight: 500;
  }

  /* .price-btn-container {
    display: flex;
    justify-content: space-between;
  } */

  button.price-btn {
    margin-right: 0px;
    height: 36px;
    width: 49%;
  }
}

.type-selector {
  width: 110px;
  height: 31px;
  border-radius: 6px;
  padding-left: 8px;
  font-weight: 500;
  font-family: inherit;
  background: #f6f6f6;
  border: none;
  outline: none;
}

.infoic {
  position: relative;
}

.infoic:hover .infoic-box {
  display: block;
}

/* .infoic-box{position: absolute; width: 300px; background-color: #fff;box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; box-sizing: border-box; padding: 15px; border-radius: 10px; display: none;height: 116px;
  overflow: scroll;} */

.infoic-box {
  position: absolute;
  width: 300px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 10px;
  /* display: block; */
  /* height: 116px; */
  /* overflow: scroll; */
  top: 35%;
  left: 30%;
}

.infoic-box h5 {
  margin: 0px;
  font-size: 12px;
  margin-bottom: 5px;
  font-family: "Inter", sans-serif;
}

.infoic-box h4 {
  margin: 0px;
  font-size: 11px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}

.infoic-box p {
  margin-top: 0px;
  font-size: 10px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}

.table-cNoScroll {
  overflow-x: unset;
}

.pdf-card {
  /* width: 25% !important; */
  /* box-shadow: 0px 4px 10px rgb(0 0 0 / 25%); */
  border-radius: 10px;
}

.pdf-card .pdf-header {
  width: 100% !important;
  box-shadow: none;
  border-radius: 10px 10px 0px 0px;
  height: 220px;
  border-bottom: 1px solid #a5a2a2;
}

.dfltCheck {
  background: #006ff2 !important;
  color: #fff;
}

.RadioBTNbutton {
  float: left;
  margin: 0 5px 0 0;
  width: 100px;
  height: 40px;
  position: relative;
}

.RadioBTNbutton label,
.RadioBTNbutton input {
  background-color: #efefef;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.RadioBTNbutton input[type="radio"] {
  opacity: 0.011;
  z-index: 100;
}

.RadioBTNbutton input[type="radio"]:checked + label {
  background: #006ff2;
  border-radius: 4px;
  color: #fff;
}

.RadioBTNbutton label {
  cursor: pointer;
  z-index: 90;
  line-height: 40px;
  text-align: center;
  border-radius: 5px;
}

.tab2,
.tab3 {
  display: none;
}

@media screen and (max-width: 600px) {
  .pd-divider .pd-right .pd-right-details {
    width: 100%;
    padding: 15px;
  }

  .RadioBTNbutton {
    width: 118px;
    height: 38px;
  }

  .product-qty-pincode {
    align-items: flex-start;
  }

  .uni-heading > span {
    margin-top: 0 !important;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 520px) {
  .infoic-box {
    position: absolute;
    width: 190px;
  }
}

.pd-sort-btn {
  height: 42px;
  border-radius: 5px;
  border: none;
  font-weight: 500;
  color: #000;
  background: unset;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 14px;
}

.sort-div input {
  display: none;
}

.sort-div label {
  height: 34px;
  display: block;
  width: 100px;
  display: inline-flex;
  align-items: center;
  background: #fdf8f8;
  color: black;
  font-size: 12px;
  justify-content: flex-start;
  cursor: pointer;
  padding-left: 5px;
}

.sort-div label:hover {
  font-weight: 500;
  background: #006ff2;
  color: white;
}

input[type="radio"]:checked + label {
  font-weight: 500;
  background: #006ff2;
  color: white;
}

.sort-div-con {
  left: 40%;
  position: relative;
}

.sort-div-con > form {
  position: absolute;
  display: none;
  padding-top: 5px;
  overflow: hidden;
}

.sort-div-con:hover form {
  display: block;
}

.sort-div {
  border: 1px solid #b9abab;
}

.sort-div:first-child {
  overflow: hidden;
  border-radius: 5px 5px 0 0;
}

.sort-div:last-child {
  overflow: hidden;
  border-radius: 0 0 5px 5px;
}

@media screen and (max-width: 570px) {
  .uni-pp {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  .uni-pp > span {
    width: 100% !important;
  }

  .RadioBTNbutton {
    clear: both;
    float: left;
    margin-bottom: 10px !important;
    width: 31% !important;
  }

  .tab1 {
    width: 100% !important;
    position: relative;
  }

  .tab2 {
    width: 100% !important;
    position: relative;
  }

  .tab3 {
    width: 100% !important;
    position: relative;
  }

  .tab1 > select {
    width: 100% !important;
  }

  .tab2 > select {
    width: 100% !important;
  }

  .tab3 > select {
    width: 100% !important;
  }

  .downarrow {
    width: 24px;
    display: block;
    position: absolute;
    right: 11px;
    top: 9px;
  }
}

.downarrow {
  display: none;
}

span.platinum,
span.gold,
span.silver,
span.premium.blue,
span.paid.blue,
span.blue {
  padding: 7px;
  border-radius: 20px;
  text-align: center;
  /* margin-right: 30px; */
  display: block;
  text-align: center;
}

span.platinum {
  background-color: #f3f3f3;
  color: #000;
}
span.gold {
  background-color: #ffd79cfa;
  color: #000;
}
span.silver {
  background-color: #cac9c7fa;
  color: #000;
}
span.premium.blue {
  background-color: #5ea8ff;
  color: #fff;
}
span.paid.blue {
  background-color: #006ff2;
  color: #fff;
}
span.blue {
  background-color: #044b9f;
  color: #fff;
}

table.seller-price-table.pdt a {
  color: #006ff2;
  text-decoration: underline !important;
  font-size: 15px;
}

/* add products css */
.addpdt form {
  margin-bottom: 30px;
}

.addpdt .form-control label {
  color: #000;
}

.addpdt .form-control textarea {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}

.addpdt h4 {
  padding-bottom: 10px;
}

.addpdt button.add {
  color: #fff;
  background: #006ff2;
  border-radius: 10px;
  border: #006ff2;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.addpdt button.add svg {
  font-size: 17px;
}

.addpdt span {
  font-size: 12px;
}

.addpdt svg.icon {
  position: absolute;
  right: 0%;
  top: 43%;
  background: #006ff2;
  border-radius: 0px 10px 10px 0px;
  color: #fff;
  font-size: 20px;
  padding: 11px;
  height: 43px;
  width: 50px;
}
