/* .seller-tabs-section{
    padding: 20px;
} */

.seller-section {
  height: 100vh;
}

.dp-img-c {
  width: 100px;
  height: 100px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.dp-img-c img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
