.pb-c {
  margin-bottom: 40px;
}

.db-number {
  font-size: 25px;
  font-weight: 600;
  color: white;
}

.ds-box.pb {
  text-align: center;
  height: 103px;
}


.pb-c .ds-box-img {
    border: 2px solid #006ff2;
    color: #006ff2 !important;
    background: white;
}

.ds-box-img.db-number.active-dimb {
    background: #006ff2;
    color: white !important;
}

@media screen and (max-width: 550px) {
  .rfq-box {
    width: 100%;
  }

  .delivery-status.pb-c {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  .pb-c .ds-box.pb {
    text-align: center;
    height: 80px;
    width: 56px;
  }

  .pb-c .db-number {
    font-size: 17px;
    font-weight: 600;
    color: white;
  }

  .pb-c .ds-heading {
    font-size: 10px;
    margin-bottom: 3px;
    font-weight: 600;
  }

  .pb-c .connector {
    width: 10%;
    height: 5px;
    background: #e9e6e6;
    position: relative;
    top: -10px;
    margin-top: 0;
    margin-bottom: 10px;
    left: 0px;
  }

  .pb-c .ds-box-img {
    width: 50px;
    height: 50px;
  }
}
