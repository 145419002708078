.seller-tabs-content-container {
}
.seller-tab-btn-container {
  display: flex;
  width: 100%;
  align-items: center;
  box-shadow: 0 10px 20px -16px #ababab;
  justify-content: space-between;
  margin-bottom: 30px;
}
.seller-tab-btn {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  font-weight: 500;
  border-bottom: 2px solid white;
  border-radius: 5px 5px 0 0;
  transition: 0.3s ease;
  cursor: pointer;
}
.seller-tab-btn.tab-active {
  /* background-color: #c6eeff; */
  border-bottom: 2px solid #006ae8;
}

.seller-content {
  display: none;
  padding: 0 30px 30px;
  text-align: left;
}
.seller-content.content-active {
  display: block;
}

.pdf-container {
  --auto-grid-min-size: 16rem;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
  grid-gap: 1rem;
}

.seller-det {
  display: flex;
  margin-bottom: 31px;
  justify-content: space-between;
  width: 50%;
}
.seller-det-left {
  font-weight: 500;
}
.seller-det-right {
  width: 270px;
  font-weight: 500;
  color: #959595;
}

.seller-form {
  width: 350px;
  margin: 50px auto;
}

.seller-input-c {
  width: 100%;
  margin-bottom: 20px;
}
.seller-input-c input {
  width: 100%;
  height: 30px;
  font-size: 15px;
  border-radius: 12px;
  background: #f4f2f2;
  border: none;
  padding: 6px 10px 6px 10px;
  outline: none;
}
.seller-input-c textarea {
  width: 100% !important;
  min-height: 50px;
  max-height: 100px;
  font-size: 15px;
  border-radius: 12px;
  background: #f4f2f2;
  border: none;
  padding: 6px 10px 6px 10px;
  outline: none;
}

.seller-form button {
  width: 100%;
  height: 38px;
  margin-top: 50px;
  border: none;
  background: #006ae8;
  border-radius: 12px;
  font-size: 15px;
  font-weight: 600;
  color: white;
  padding: 6px 0px;
  cursor: pointer;
}

.seller-about-btn {
  width: 200px;
  height: 38px;
  margin-top: 30px;
  border: none;
  background: #006ae8;
  border-radius: 12px;
  font-size: 15px;
  font-weight: 600;
  color: white;
  padding: 6px 0px;
  cursor: pointer;
  margin-inline: auto;
}

.seller-input-c input,
.seller-input-c textarea,
.seller-form button {
  width: -webkit-fill-available !important;
}

.seller-content-container {
  /* width: 80%; */
  margin-inline: auto;
  text-align: left;
}

.seller-banner {
  width: 100%;
  margin-bottom: 10px;
  height: 300px;
  object-fit: cover;
}

@media screen and (max-width: 1000px) {
  .seller-tab-btn-container {
    flex-direction: column;
  }
  .stbc-c {
    width: 100%;
  }
  .seller-tab-btn {
    width: 25%;
  }
  .seller-det {
    width: 100%;
  }

  .seller-content {
    display: none;
    padding: 30px 30px;
    text-align: left;
  }
}

@media screen and (max-width: 570px) {
  .seller-tab-btn {
    font-size: 13px;
    text-align: center;
  }

  .seller-form {
    width: 100%;
  }

  .seller-det-right {
    width: 170px;
    font-weight: 500;
    color: #959595;
  }

  .seller-det {
    width: 100%;
  }
  .seller-input-c input,
  .seller-input-c textarea,
  .seller-form button {
    width: -webkit-fill-available !important;
  }

  .seller-content {
    display: none;
    padding: unset;
    text-align: left;
    padding-top: 20px;
  }

  .seller-content-container {
    width: 95%;
  }
}

/* .w8-div{
margin-inline: auto;
  width: 80%;
} */

/* newtab css */

.new-seller-content.content-active {
  display: flex !important;
  justify-content: center;
}
/* .document-card-container {
  max-width: 928px;
} */

@media screen and (max-width: 1250px) {
  .document-card-container {
    max-width: 800px;
  }
}

@media screen and (max-width: 780px) {
  .pdf-container {
    --auto-grid-min-size: 8rem;
  }
}
