.splfilter-container {
  display: none;
  align-items: center;
  padding: 27px 10px;
  justify-content: center;
}
@media screen and (max-width:1000px){
  .splfilter-container{
    display: flex;
  }
}
.splfilter-head {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.splfilter-head:first-child {
  border-right: 1px solid;
}
.splfilter-head img {
  cursor: pointer;
}
.splfilter-head .splfilter-title {
  font-size: 21px;
  margin-left: 5px;
  font-weight: 500;
  cursor: pointer;
}





.sort-box{
    display: none;
}
.sb-a{
    display: block !important;
}
.sortbox-head {
  border-bottom: 1px solid #d6d0d0;
  padding: 20px;
  border-radius: 10px 10px 0 0;
}
.sortbox-title {
    font-size: 18px;
    font-weight: 500;
    margin-left: 5px;
}
.main-sort {
  position: fixed;
  z-index: 999;
  background: white;
  width: 100%;
  bottom: 0;
  border-radius: 10px 10px 0 0;
  animation-name: example2;
  animation-duration: 0.3s;
  animation-delay: 0s;
  animation-fill-mode: both;
}
@keyframes example2 {
    from {
        /* opacity: 0; */
        bottom: -270px;
    }
    to {
        /* opacity: 1; */
        bottom: 0;
    }
  }
.sort-overlay {
  background: black;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  opacity: 0.6;
}

.sortbox-radios {
  padding: 20px;
}
.radios-c {
  margin-bottom: 20px;
}

