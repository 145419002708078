section.buyers-section.rfq-sec {
  background: white;
}

/* BASIC INFORMATION */

.rfq-box {
  /* width: 80%; */
  margin: auto;
}

table caption {
  caption-side: top;
}
/* RFQ REQUEST */

.greyblue-box {
  background: #f4f4f4;
  border-radius: 10px;
}

.greypart {
  padding: 20px 20px;
  border-radius: 10px 10px 0 0;
  font-weight: 500;
}

.bluepart {
  background: #c6eeff;
  border-radius: 10px;
  padding: 18px 40px;
}

.rfq-searchbox {
  display: flex;
  position: relative;
  width: 100%;
}

.rfq-searchbox input {
  border: none;
  width: 100%;
  background: #f3f3f3;
  border-radius: 7px;
}

.rfq-searchbox button {
  border: none;
  border-radius: 6px;
  padding: 5px 11px;
  color: white;
  background: #006ff2;
  position: relative;
  left: -12px;
}

.bluepart p {
  font-size: 13px;
  margin: 10px 0;
  font-weight: 500;
  text-align: center;
}

.select-tag-container {
  display: flex;
  justify-content: space-between;
  padding: 25px 0;
}

.select-tag-container select {
  width: 32%;
  height: 40px;
  border-radius: 7px;
  border: none;
  padding: 0 10px;
  font-weight: 600;
  font-size: 14px;
  color: #696767;
  outline: none;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
}

/* for IE10 */
select::-ms-expand {
  display: none;
}

.rfqreq-btn {
  background: #006ff2;
  color: white;
  font-weight: 500;
  border-radius: 5px;
  width: 120px;
  font-size: 15px;
  height: 35px;
  margin-inline: auto;
  border: none;
  display: block;
}

/* RFQ SELECTION */

.rfq-hr {
  height: 55px !important;
  background: #c6eeff;
  font-size: 15px !important;
}

.rfq-tab {
  width: 100%;
  margin: 100px 0;
}

/* RFQ FORM */

.rfq-form-head {
  font-size: 20px;
  font-weight: 500;
  color: gray;
  margin-bottom: 10px;
}

.rfq-form-head strong {
  color: black;
  margin-right: 7px;
}

.rfq-form-date {
  font-weight: 500;
  color: black;
  font-size: 15px;
  margin-bottom: 50px;
}

.up-heading {
  font-weight: 600;
  margin-bottom: 20px;
}

.sub-up-heading {
  font-weight: 600;
  font-size: 12px;
  color: gray;
  margin-bottom: 30px;
}

.rfq-product-container {
  margin-bottom: 50px;
}

.rfq-table {
  margin: 20px 0 50px;
}

.rfq-table .rfq-tab {
  margin: 0 0;
}

.rfq-product-card {
  display: flex;
  align-items: center;
  /* width: 80%; */
  background: #f3f3f3;
  margin-bottom: 20px;
  border-radius: 9px;
  justify-content: space-between;
  padding: 20px;
}

.rfq-product-card h1 {
  margin: 0;
  font-size: 17px;
}

.rfq-product-card p {
  font-weight: 600;
  color: gray;
  font-size: 13px;
}

.rfq-ta textarea {
  border-radius: 10px;
  background: #f3f3f3;
  padding: 20px;
  width: 95%;
  border: none;
  min-height: 90px;
  max-width: 95%;
  margin-bottom: 50px;
}

@media screen and (max-width: 700px) {
  .min-input-c {
    flex-direction: column;
  }

  .rfq-ta textarea {
    width: 86%;
  }
}

.rfq-box .connector {
  width: 27%;
}

/* rfqs form css */
.profile_page .head {
  text-align: left;
  color: #7b7b7b;
  padding-bottom: 20px;
}

.order_list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  gap: 30px;
}

.order_list::-webkit-scrollbar{
  display: none;
}

.order_list .single {
  background: #ffffff;
  border: 2px solid #d9d9d9;
  border-radius: 10px;
  padding: 20px 10px;
  width: 50%;
  height: 100%;
}

.order_list .single:hover {
  background: #f1fbff;
  border: 2px solid #006ff2;
}

.order_list .single span.MuiTypography-root {
  text-align: left;
    font-size: 14px;
    color: #515151;
    padding: 5px 0;
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root{
  padding: 5px;
}

.order_list .single:hover span.MuiTypography-root {
  color: #006ff2;
}

.order_list .single h6 {
  font-size: 18px;
  color: #515151;
  padding-left: 60px;
  /* padding-bottom: 10px; */
}

.order_list .single:hover h6 {
  color: #006ff2;
}
.paddingInputs20{
  padding: 0 20px;
}

.order_list .single label {
  gap: 18px;
  align-items: center;
}

.profile_page .searchPendingItem {
  display: flex;
  gap: 10px;
  align-items: center;
}

.profile_page .searchPendingItem input {
  background-color: #fff;
}

.profile_page .searchPendingItem span.search {
  background: #006ff2;
  border: unset;
  border-radius: 8px;
  color: #fff;
  padding: 10px 20px;
}

.profile_page .searchPendingItem span.filter {
  background: #f4f4f4;
  border: unset;
  border-radius: 8px;
  color: #000;
  padding: 10px 20px;
}

.profile_page .searchPendingItem span.filter svg {
  font-size: 17px;
}

.pdt_Detail {
  padding: 20px 0 50px;
}

button:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.pdt_Detail h6 {
  background: #f7f7f7;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  gap: 20px;
}

.pdt_Detail .product-qty {
  border: 1px solid #d9d9d9;
  padding: 20px 20px 40px;
  display: flex;
  gap: 40px;
}

th.product-names, 
td.product-names{
  width: 200px;
}

th.qty-names, 
td.qty-names{
  width: 100px;
}

.pdt_Detail .product-qty input {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: #fff;
  /* width: 200px; */
}

.pdt_Detail .product-qty-input button {
  width: 50px;
}

.pdt_Detail .uni-heading {
  font-size: 15px;
}

.profile_page .table-c {
  width: 100%;
  overflow-x: unset;
}

/* .profile_page .tags {
  display: flex;
  gap: 30px;
} */

.blue span,
.pdt .tablerow-data .premium span {
  background: #044b9f;
  padding: 7px;
  color: #fff;
  border-radius: 20px;
  text-align: center;
  margin-right: 30px;
  display: block;
}
.rfq-page .pdt_Detail h6 {
  background: #f7f7f7;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  gap: 20px;
  margin: 30px 0;
}
.rfq-page .inputs {
  display: flex;
  padding-bottom: 20px;
  align-items: center;
}
.rfq-page .pdt_Detail {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 10px;
}
.rfq-page .column2 {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 10px;
}

.gold span {
  background: rgba(255, 215, 156, 0.98);
  padding: 7px;
  border-radius: 20px;
  text-align: center;
  margin-right: 30px;
  display: block;
}

.payment_milestone_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.payment_milestone_flex .label_title {
  color: #006ff2;
}
.payment_milestone_flex_inputs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.payment_milestone_flex_inputs .form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.platinum span {
  background: rgba(242, 242, 242, 0.98);
  padding: 7px;
  border-radius: 20px;
  text-align: center;
  margin-right: 30px;
  display: block;
}

.profile_page .tags .MuiFormControl-root {
  background: #ffffff;
  border: 2px solid #d9d9d9;
  border-radius: 10px;
  width: 100%;
  padding: 10px;
}

.profile_page .tags .MuiFormControl-root:hover {
  background: #f1fbff;
  border: 2px solid #006ff2;
  border-radius: 10px;
}

.profile_page .tags label.seller {
  margin-bottom: 24px;
  display: block;
  color: #7b7b7b;
  font-size: 15px;
}

.profile_page .tags label.vendor {
  display: flex;
  align-items: center;
  justify-content: end;
}

.profile_page .tags .infoic-box {
  top: 118%;
  left: 70%;
  z-index: 1;
}

.profile_page ul.summary.step4 li:nth-child(4) {
  border-bottom: 10px solid #006ff2;
  width: 100%;
  color: #006ff2;
}

.profile_page ul.summary.step5 li:nth-child(5) {
  border-bottom: 10px solid #006ff2;
  width: 100%;
  color: #006ff2;
}

.profile_page .form-inputs {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 10px;
}

.profile_page .form-inputs svg {
  padding: 8px;
  font-size: 40px;
  background: #e9f3ff;
  color: #006ff2;
  border-radius: 50%;
  transform: translate(400px, -15px);
}

.profile_page .inputs {
  display: flex;
  padding-bottom: 30px;
}

.profile_page .inputs a {
  font-size: 15px;
  text-decoration: underline !important;
  color: #006ff2;
}

.profile_page .inputs span {
  color: #7b7b7b;
  /* width: 20%; */
  font-size: 14px;
}

button.continue {
  background: #042d5f;
  border: 1px solid #042d5f;
  border-radius: 8px;
  color: #fff;
  padding: 10px 20px;
  width: 50%;
}

button.cancel {
  background: #fff;
  border: #fff;
  color: #042d5f;
  padding: 10px;
  width: 50%;
}

.profile_page .inputs span svg {
  background: #e9f3ff;
  color: #006ff2;
  padding: 8px;
  border-radius: 37px;
  font-size: 40px;
  transform: translate(450px, -8px);
}

.profile_page .inputs label {
  width: 30%;
  display: inline-block;
  font-size: 14px;
}

.pdt_Detail label {
  padding-bottom: 20px;
  display: inline-block;
}

.profile_page .custom-file-upload {
  padding-top: 30px;
}

.profile_page .custom-file-upload label.label {
  display: inherit;
  padding-bottom: 20px;
}

.profile_page .custom-file-upload textarea {
  border: 1px solid #d9d9d9;
  width: 100%;
  border-radius: 8px;
  padding: 10px;
}

.profile_page .custom-file-upload input {
  border: 1px solid #d9d9d9;
  padding: 10px;
  width: 100%;
  border-radius: 8px;
  position: relative;
}

.profile_page .custom-file-upload input:after {
  content: "Upload File";
  position: absolute;
  background: #f3f3f3;
  left: 0%;
  width: 85px;
  padding: 13px 6px;
  text-align: center;
  top: -1%;
}

.profile_page p.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile_page a.more {
  color: #006ff2;
  text-decoration: underline !important;
  font-size: 15px;
}

/* rfq css */
.rfq.profile_page .input-type {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 0px;
}

.rfq.profile_page .additional h6 {
  font-size: 16px;
  color: #000;
  padding: 15px 0;
}

.rfq.profile_page .additional {
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 20px;
  margin-bottom: 30px;
}

.rfq.profile_page .additional .inputs {
  padding-bottom: 10px;
}

.rfq.profile_page .additional .inputs label {
  color: #7b7b7b;
}

.rfq.profile_page .top {
  display: flex;
  gap: 40px;
}

.rfq.profile_page .top p {
  color: #7b7b7b;
  font-size: 15px;
}

.rfq.profile_page .bottom .btn-input {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.rfq.profile_page .bottom .btn-input .accept {
  background: #006ff2;
  border: 1px solid #006ff2;
  border-radius: 8px;
  color: #fff;
  padding: 10px 20px;
}

.rfq.profile_page .bottom .btn-input .response {
  background: #e8e8e8;
  border: #e8e8e8;
  border-radius: 8px;
  color: #fff;
  padding: 10px 20px;
}
/* 
.rfq.profile_page .bottom .form-input {
  display: flex;       gap: 60px;
}

.rfq.profile_page .bottom .form-input .inputs {
  display: flex;
  align-items: center;
  padding: 0;
  gap: 60px;
}
.rfq.profile_page .bottom .form-input .inputs label{
 width: unset;
}
.rfq.profile_page .bottom .form-input .inputs p{
  color: #7B7B7B;
  font-size: 15px;
} */
.rfq.profile_page .bottom .account {
  display: flex;
  gap: 20px;
  padding-bottom: 30px;
}

.rfq.profile_page .bottom .account .detail {
  display: flex;
  width: 50%;
}
.rfq.profile_page .bottom .account .detail label {
  width: 200px;
}
.rfq.profile_page .bottom .account .detail span {
  color: #7b7b7b;
}

label.required::after {
  content: " *";
  color: #ff0000;
}

.rfq.profile_page .bottom .css-1wnsr1i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px,
    rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  padding: 32px;
}
.css-0 h3 {
  margin-top: 0;
}
.response-modal .form-control label {
  display: block;
  font-size: 15px;
  padding-bottom: 10px;
}
.response-modal .form-control input {
  padding: 10px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}
.response-modal .form-control textarea {
  padding: 10px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}
.response-modal .form-btn {
  display: flex;
  gap: 20px;
}
.response-modal .form-btn button.cancel {
  background: #e8e8e8;
  border: #fff;
  color: #000;
  padding: 10px 20px;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  width: 100%;
}
.response-modal .form-btn button.continue {
  background: #006ff2;
  border: 1px solid #006ff2;
  border-radius: 8px;
  color: #fff;
  padding: 10px 20px;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .order_list {
    display: block;
  }

  .order_list .single {
    margin-bottom: 20px;
  }

  .profile_page h6 {
    margin-bottom: 20px;
    font-size: 12px;
    padding: 10px;
  }

  .pdt_Detail .product-qty {
    display: block;
  }

  .pdt_Detail .product-qty input {
    width: 100%;
  }

  .pdt_Detail div {
    padding-top: 10px;
  }

  .profile_page .table-c {
    overflow-x: scroll;
  }

  .profile_page table.seller-price-table.pdt {
    min-width: 2px;
    margin-top: 0;
  }

  .profile_page table.seller-price-table.pdt {
    min-width: 440px;
  }

  .profile_page .tags .MuiFormControl-root {
    padding: 0;
  }

  .profile_page .tags label.vendor {
    font-size: 12px;
  }

  .profile_page .inputs label {
    width: 50%;
    font-size: 14px;
  }

  .profile_page .inputs span {
    font-size: 14px;
    width: unset;
    font-size: 14px;
  }

  .profile_page .inputs {
    gap: 10px;
  }

  .profile_page a.more {
    margin-bottom: 20px;
    display: inline-block;
  }
}
