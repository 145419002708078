.pdf-card {
  width: 100%;
  position: relative;
  
  
}
.pdf-card > img{position: absolute;
  right: 15px;
  z-index: 1;
  height: 17px;
  top: 15px; cursor: pointer; width: 4px;}
.pdf-header {
  width: inherit;
  height: 270px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #27a7de;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
  border-radius: 9px;
  position: relative;
}
.pdf-details {
  margin: 15px 0 20px;
  text-align: center;
  display: flex;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    text-align: left;
}
.pdf-name {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 7px;
}

.pdf-size {
    font-weight: 500;
    color: gray;
    font-size: 15px;
    margin-bottom: 10px;
}
.pdf-details button {
  font-family: inherit;
    width: 90%;
    border-radius: 5px;
    border: none;
    height: 30px;
    font-weight: 600;
    color: white;
    background: #006FF2;
    margin-inline: auto;
    display: block;
    
}


@media screen and (max-width:650px) {
  .pdf-header {
    width: 120px;
    height: 143px;
}
/* .pdf-card {
    width: max-content;
    margin: 20px 10px;
} */
}

/* Newpdf css */

.new-pdf-header {
  width: inherit;
  box-shadow: none;
  border-radius: 0;
  border-bottom: 1px solid #A5A2A2;
}
.new-pdf-card {
  /* width: 287px; */
  width: 100%;
  border: 1px solid #A5A2A2;
  border-radius: 8px;
  overflow: hidden;
}
.new-pdf-details {
  margin: 10px 10px 10px;
  text-align: left;
  position: relative;
}
.new-pdf-name {
  font-size: 15px;
  color: #575757;
  font-weight: 400;
  margin-bottom: 7px;
}
.new-pdf-size {
  font-weight: 400;
  color: #575757;
  font-size: 15px;
  /* margin-bottom: 10px; */
}
.documentActions{list-style: none;
  width: 144px;
  position: absolute; padding-left: 0;right: 5px;
  border-radius: 3px;
  background: #fff;
  right: 0px;
  z-index: 1; display: none;
  top: 15px;box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;}
  .documentActions li{height: 30px; padding: 10px; line-height: 30px; cursor: pointer;}
  .documentActions li:first-child{border-bottom: 1px solid #929292;}
  .PDFActionIcon:hover + .documentActions{display: block;}
  .documentActions:hover{display: block;}
.css-i4bv87-MuiSvgIcon-root{cursor: pointer;}
.download-icon{
  position: absolute;
  top: 25%;
  right: 0px;
  color: #33363F;
}
.option-icon{
  position: absolute;
  top: 10px;
  right: 0px;
  color: #33363F; 
}




@media screen and (max-width:550px){
  .pdf-container {
    justify-content: space-between;
    
}
/* .new-pdf-card {
    width: 45% !important;
    margin: 0;
}

.pdf-card {
  width: 45% !important;
  margin: 0 !important;
} */

.pdf-card .pdf-header {
    height: 117px;
    width: 100%!important;
}

.pdf-details {
  margin: 10px 0 10px;
}
.pdf-name {
  font-size: 12px;
}
.pdf-size {
  font-weight: 400;
  font-size: 11px;
}
.download-icon {
  right: 8px;
}

}

 