* {
  box-sizing: border-box;
}

button {
  cursor: pointer;
  text-decoration: none !important;
  font-family: "Roboto", sans-serif;
  font-style: inherit;
}

div.form-control {
  border: none;
}

label.required::after {
  content: "*";
  color: #f00;
}

.profile_page .form-control.country svg{
  position: relative;
}

a {
  text-decoration: none !important;
}

.df {
  display: flex;
}

.aic {
  align-items: center;
}

.fdc {
  flex-direction: column;
}

.jcb {
  justify-content: space-between;
}

.cart-p {
  padding: 10px 35px;
}

.w100 {
  width: 100%;
}

.tc {
  text-align: center;
}

.m-20 {
  margin: 20px 0;
}

.mt0 {
  margin-top: 0 !important;
}

.btn-con {
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
}

.backbtn-g {
  border: 1px solid #006ff2;
  color: #006ff2;
  font-weight: 400;
  width: 32%;
  margin-right: 10px;
  text-align: center;
  height: 40px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.conbtn-g {
  border: 1px solid #006ff2;
  background-color: #006ff2;
  color: white;
  font-weight: 400;
  width: 150px;
  /* width: 32%; */
  /* margin-right: 10px; */
  text-align: center;
  height: 40px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.jcc {
  justify-content: center;
}

.dnd {
  display: inline-flex !important;
}

.dn {
  display: none;
}

input[type="radio"] {
  border: 1px solid #fff;
  /* padding: 0.5em; */
  height: 15px;
  width: 15px;
  -webkit-appearance: none;
  border-radius: 3px;
  overflow: hidden;
  margin: 0;
}

input[type="radio"]:checked {
  background: #006ff2;
  background-size: 9px 9px;
}

input[type="radio"]:focus {
  outline-color: transparent;
}

@media screen and (max-width: 1200px) {
  .bsd-left {
    display: none !important;
  }
}

@media screen and (max-width: 550px) {
  .conbtn-g,
  .backbtn-g {
    font-size: 12px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* .css-1tcbki6{width: 47px !important;
  height: 20px !important;}
  .css-1tcbki6 .MuiSwitch-track{background: #fff;}
  .css-19gndve{    width: 18px !important;
    height: 18px !important;} */
.css-1tcbki6 .MuiSwitch-switchBase.Mui-checked {
  right: 9px;
}

.css-1tcbki6 {
  width: 47px !important;
  height: 22px !important;
}

.css-1tcbki6 .MuiSwitch-track {
  background: #fff;
}

.css-19gndve {
  width: 18px !important;
  height: 18px !important;
}

.hehe-div .css-1q36rmd-MuiStack-root {
  width: 100% !important;
  min-width: 230px !important;
  justify-content: space-between !important;
}

.hehe-div .css-1g66d9k-MuiSwitch-root {
  width: 46px !important;
  height: 20px !important;
  border-radius: 10px !important;
}

.hehe-div .css-1g66d9k-MuiSwitch-root .MuiSwitch-thumb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.hehe-div .css-vb6e92 {
  justify-content: space-between;
}

.hehe-div .css-1s6m0eo {
  width: 42px !important;
  height: 18px !important;
}

.hehe-div .css-19gndve {
  height: 15px !important;
  width: 15px !important;
  border-radius: 50% !important;
}

.hehe-div .MuiSwitch-switchBase {
  left: 0px !important;
}

.hehe-div .Mui-checked {
  right: 0px !important;
}
