.ph-card {
  /* box-shadow: 0px 0px 6px rgb(0 0 0 / 25%); */
  /* border-radius: 15px; */
  /* padding: 10px; */
  /* align-items: center; */
  /* justify-content: space-around; */
  /* height: 150px; */
  width: max-content;
  position: relative;
}
.ph-card img {
  height: 100%;
  margin-right: 20px;
}
.ph-card .ph-name {
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  color: black;
}
.ph-card p {
  font-weight: 500;
  color: gray;
  font-size: 12px;
}
.ph-card .ph-price {
  font-weight: 500;
  font-size: 24px;
}

.ph-card .product-button{
  display: none;
}

/* button.delete {
  position: absolute;
  border: none;
  top: 5px;
  right: 5px;
  background: none;
  color: #aba7a7;
} */
button.delete {
  position: absolute;
  border: none;
  top: 3px;
  left: 3px;
  background: none;
  color: #aba7a7;
}
.hc-card {
  display: inline-flex;
  margin: 0 10px;
  flex-direction: column;
  flex-wrap: wrap;
}

.history-btn {
  margin: 0px 0 20px 0;
  display: block;
  width: 264px;
  height: 33px;
  border-radius: 7px;
  background: #006ff2;
  border: none;
  font-weight: 600;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rhc .ph-card .product-card{
  margin: 10px;
}

.rhc .ph-card  button.delete {
  top: 10px;
  left: 9px;}

@media screen and (max-width: 650px) {
  /* .ph-card {
    height: max-content;
    flex-direction: column;
} */
.hc-card {
  width: -webkit-fill-available;
}
.ph-card{
  width:100%;
  /* width: fit-content; */
}
.rhc .ph-card .product-card{ 
  width:100%;
  margin: 0px;
  /* width: fit-content; */
}
}

@media screen and (max-width:550px) {
  .history-btn {
    width: 98%;
  }
}
