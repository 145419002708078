.min-input-form {
    display: flex;
    width: 40%;
    flex-direction: column;
}
.min-input-form label {
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 17px;
}
.min-input-form input {
    height: 29px;
    border-radius: 6px;
    border: none;
    background: #ECECEC;
    padding: 3px 10px;
}

@media screen and (max-width:700px){
    
    .min-input-form {
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-bottom: 20px;
    }
}