.moblogin-container {
    width: 550px;
    margin-inline: auto;
    padding: 20px;
    margin-bottom: 50px;
}
.back-arrow {
    margin-bottom: 10px;
    color: #8c8c8c;
    cursor: pointer;
}
.mob-heading {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 28px;
}
.mob-input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.mob-input-container label {
    font-weight: 500;
    margin-bottom: 5px;
    color: #006FF2 ;
    font-size: 13px;
    cursor: pointer;
}
.mob-input-container input{
    height: 36px;
    border-radius: 6px;
    border: none;
    background: #ebebeb;
    padding: 5px;
}
.mob-input-container textarea{
    min-height: 50px;
    border-radius: 6px;
    border: none;
    background: #ebebeb;
    padding: 5px;
    max-width: 100%;
    max-width: 100% !important;
}

.forget-p {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    margin-top: -10px;
    font-weight: 500;
    color: #006FF2;
    cursor: pointer;
}

.mob-btn {
    width: 100%;
    height: 40px;
    margin-top: 50px;
}

.blue-p {
    font-size: 12px;
    font-weight: 600;
    color: #006FF2;
    margin-bottom: -39px;
    text-align: center;
    cursor: pointer;
}


@media screen and (max-width:570px){
    .moblogin-container {
        width: -webkit-fill-available;
    }
}


.forget-b{
    display: flex;
    font-size: 12px;
    margin-top: -10px;
    font-weight: 500;
    color: #696b6d;
    cursor: pointer;
}

.uno-div{
    display: flex;
    flex-direction: column;
    height: 41px;
    align-items: flex-start;
    justify-content: space-around;
    margin-top: 20px;
}


.option-heading{
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 11px;
    margin-top: 20px;
}

.option {
    color: #006ff2;
    margin: 0 6px;
    cursor: pointer;
}


.progess-line {
    margin-bottom: 20px;
}
.progress-container {
    height: 10px;
    width: max-content;
    background: #ebebeb;
    width: 100%;
    border-radius: 18px;
    padding: 3px;
    margin-top: 3px;
}
.progess {
    width: 80%;
    height: 100%;
    background: #006ff2;
    position: relative;
    display: block;
    border-radius: 21px;
}
.progess.p2{
    width: 100%;
}

.black-p{
    font-size: 12px;
    font-weight: 600;
    color: black;
    margin-bottom: -39px;
    text-align: center;}
