section.buyers-section {
  background: #042d5f;
  min-height: 100vh;
}

/* .buyers-section-div {
  display: flex;
  width: 95%;
  margin-inline: auto;
  padding: 0px 0;
}
.bsd-left {
  width: 19%;
} */
.buyers-section-div {
  display: flex;
  width: 100%;
  margin-inline: auto;
  padding: 0px 15px;
}

.bsd-left {
  width: 25.7%;
}

.documentCart .pdf-card {
  width: 22% !important;
  margin-left: 1.5%;
  margin-right: 1.5%;
  margin-bottom: 30px;
}

/* .bsd-right {
  width: 84%;
  background: white;
  padding: 70px;
  border-radius: 10px;
  margin: 20px 0;
  min-height: 100vh;
  position: relative;
  box-sizing: border-box;
} */
.bsd-right {
  width: 75%;
  background: white;
  padding: 20px;
  /* padding: 70px; */
  /* border-radius: 10px; */
  border-radius: 5px;
  margin: 20px 0;
  min-height: 100vh;
  position: relative;
  box-sizing: border-box;
}

.DashCard-Wallet {
  border-radius: 5px !important;
}

.bottom-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.buyers-profile-box {
  /* padding: 0 20px; */
  margin-left: auto;
}

/* BUYERS PROFILE PAGE */

.buyers-profile-banner {
  width: 100%;
  height: 183px;
  overflow: hidden;
  position: relative;
  border-radius: 20px;
}

.buyers-profile-banner img {
  height: 100%;
  width: 100%;
}

.buyers-dp {
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #dfdfdf;
  position: relative;
  top: -59px;
  left: 50px;
}
.buyers-dp img {
  max-width: 100%;
  height: auto;
}

.buyers-name {
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 20px;
  padding-left: 53px;
  margin-top: -40px;
}

.edit-circle {
  width: 30px;
  height: 30px;
  background-color: #006ff2;
  border-radius: 50%;
  top: 10px;
  right: 10px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.greybox {
  color: gray;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 20px;
  padding-left: 55px;
}

.greybox-i {
  color: rgb(223, 223, 223);
  margin-right: 13px;
}

/* BUYERS HISTORY PAGE */

.history-container {
  margin: auto;
}

/* BUYERS DOCUMENTS */

.bd-sec {
  display: inline-flex;
  position: relative;
  margin-inline: auto;
  flex-wrap: wrap;
  width: 100%;
  /* width: 90%; */
}

/* BUYERS RFQ'S */

.rfq-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.active-sidebar {
  left: 0 !important;
}

/* BUYERS RFQ DETAILS */

.rfqdh-c {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.rfqdh-c:first-child {
  margin-right: 20px;
}

.gt {
  font-size: 20px;
  font-weight: 500;
  color: gray;
}

.bt {
  font-size: 13px;
  font-weight: 500;
  margin-top: 6px;
}

.line {
  width: 100%;
  height: 2px;
  margin: 20px 0;
  background: #dedbdb;
}

.rit-table-container {
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  margin-bottom: 25px;
}

.rit-header {
  display: flex;
  justify-content: space-between;
  background: #f3f3f3;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  align-items: center;
  padding: 10px;
  border-radius: 7px 7px 0 0;
}

.rit-left {
  width: 70%;
}

.rit-right {
  width: 28%;
}

.rit-body {
  padding: 10px;
  background: #f3f3f3;
  margin-top: 10px;
  min-height: 60px;
}

.rit-body > h2 {
  margin: 0px 0 10px;
  font-size: 20px;
}

.link {
  font-size: 11px;
  font-weight: 600;
  color: #0067e1;
  text-decoration: underline;
  cursor: pointer;
}

.rit-body .rit-left-divs {
  width: 50%;
  display: inline-flex;
  flex-direction: column;
}

.rit-body .rit-left-divs:last-child {
  text-align: end;
}

.rit-body .rit-left-divs div {
  font-weight: 500;
  font-size: 14px;
}

.rit-body .rit-left-divs p {
  font-size: 13px;
  font-weight: 500;
  color: gray;
  margin: 5px 0;
}

.rit2-table {
  margin: 30px 0 10px;
}

.rit2-header {
  display: flex;
}

.rit2-header > div {
  font-weight: 500;
  font-size: 14px;
  width: 34%;
}

.rit2-body {
  display: flex;
}

.rit2-block {
  font-size: 14px;
  font-weight: 500;
  color: gray;
  width: 34%;
  margin: 10px 0 30px;
}

.rit3-table {
  margin: 15px 0 25px;
  width: 100%;
}

.rit3-header {
  display: flex;
  background: #f3f3f3;
  padding: 14px;
  border-radius: 5px 5px 0 0;
}

.rit3-header > div {
  width: 20%;
  font-weight: 500;
  font-size: 16px;
}

.rit3-row {
  display: flex;
  padding: 14px;
}

.rit3-info {
  width: 20%;
}

.bb-btn {
  color: #0067e1;
  font-weight: 500;
  cursor: pointer;
}

/* @media screen and (max-width:700px){

} */

.swallet {
  margin-top: 30px;
  display: block;
}

.grey {
  color: grey;
}

form.create-form {
  margin: 30px 0;
}

.new-input-c {
  margin-bottom: 20px;
}

.new-input-c label {
  font-weight: 500;
}

.new-input-c input {
  width: 75%;
  height: 32px;
  border-radius: 6px;
  padding: 5px 10px;
  background: #f3f3f3;
  border: none;
}

.table-parent {
  overflow-y: scroll;
}

.edb {
  padding-left: 51px;
  margin-bottom: 21px;
  position: relative;
  top: -37px;
}

/*new switches design*/
.switchVKG {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switchVKG input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sliderVKG {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sliderVKG:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .sliderVKG {
  background-color: #2196f3;
}

input:focus + .sliderVKG {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .sliderVKG:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.sliderVKG.roundVKG {
  border-radius: 34px;
}

.sliderVKG.roundVKG:before {
  border-radius: 50%;
}

.AddressSwitches p {
  font-weight: 500;
}

.SubAcntHedng {
  color: #767676;
  font-weight: 700;
  font-size: 24px;
}

/*Add SubAccount */
.CustRow {
  width: 100%;
  display: block;
  margin-top: 65px;
}

.CustCol {
  width: 230px;
  float: left;
  margin-right: 150px;
}

.AddressSwitches {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.ConfirnFilt {
  width: 140px;
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c7c7c7;
  margin-bottom: 27px;
  height: 40px;
}

.ConfirnFilt_Outer {
  max-width: 300px;
  /* position: relative; */
  /* z-index: 9999; */
}

.Allfilterinner {
  height: auto;
  box-sizing: border-box;
  padding: 15px;
  display: none;
  /* position: absolute; */
  background: #fff;
  width: 100%;
  /* z-index: 9; */
  top: 45px;
  border-radius: 5px;
  border: 1px solid #c7c7c7;
}

.react-calendar {
  border-radius: 5px;
  border: 1px solid #c7c7c7 !important;
  box-sizing: border-box;
  padding: 5px;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__month-view__days__day--weekend {
  color: #000 !important;
}

.react-calendar__navigation {
  height: 33px !important;
  margin-bottom: 0em !important;
}

.rit3-header82 {
  background-color: #fff !important;
}

.rit3-row82 {
  background: #f4f4f4;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #c7c7c7;
  margin-bottom: -1px;
}

.rit3-header82 > div:last-child {
  text-align: center;
}

.rit3-row82 a {
  color: #006ff2;
  text-decoration: none;
}

.rit3-row82 > div:last-child {
  text-align: center;
  text-decoration: underline;
}

.searchPendingItem {
  position: relative;
}

.searchPendingItem img {
  cursor: pointer;
  height: 23px;
  right: 13px;
  top: 7px;
  position: absolute;
}

.searchPendingItem input {
  height: 35px;
  background: #f4f4f4;
  padding: 10px;
  width: 100%;
  /* float: left; */
  display: block;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  position: relative;
}

.rangeaouterDiv {
  display: flex;
  justify-content: space-between;
}

.rangeaouterDiv div {
  height: 40px;
  width: 100px;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  line-height: 40px;
  text-align: center;
}

.hideRageSliderDivfix {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 999;
  display: none;
}

.MuiSlider-track {
  height: 3px;
}

.css-1ji7omv-MuiBackdrop-root {
  display: none !important;
}

.bsd-right8247 {
  padding: 0px;
}

.Outer-bsd-right8247 {
  padding: 20px 20px 20px 20px;
}

@media screen and (max-width: 1200px) {
  .bsd-right {
    width: 100%;
  }
}

@media screen and (max-width: 1100px) {
  .buyers-section-div {
    width: 95%;
  }

  .bsd-right {
    width: 100%;
    padding: 20px;
  }

  .documentCart .pdf-card {
    width: 30% !important;
    margin-bottom: 20px !important;
  }
}

@media screen and (max-width: 700px) {
  .greybox {
    color: gray;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 20px;
    padding-left: 15px;
  }

  .bsd-left {
    position: unset;
    width: unset;
  }

  .bsd-right {
    width: 80%;
    margin-inline: auto;
  }

  /* .bsd-right.rfq-bsdr {
      width: 100%;
      margin-inline: auto;
    } */
  .bsd-right.rfq-bsdr {
    width: -webkit-fill-available;
    /* margin-inline: auto; */
  }

  .buyers-section-div {
    width: 100%;
  }

  .buyers-name {
    padding-left: 9px;
  }

  .bd-sec {
    justify-content: space-evenly;
  }

  .edb {
    padding-left: 11px;
    top: -37px;
  }

  .documentCart .pdf-card {
    width: 45% !important;
    margin-bottom: 20px !important;
  }

  .bsd-right {
    width: 95%;
    margin-inline: auto;
    padding: 20px;
  }

  .bsd-right.bsd-right8247 {
    padding: 0;
  }
}

@media screen and (max-width: 660px) {
  .bw-container .rit3-table {
    width: 650px;
  }

  .Outer-bsd-right8247 {
    padding: 10px 10px;
  }

  .searchPendingItem img {
    height: 16px;
    right: 11px;
    top: 9px;
  }

  .searchPendingItem input {
    font-size: 9px;
  }
}

@media screen and (max-width: 550px) {
  .new-input-c {
    flex-direction: column;
    align-items: baseline;
  }

  .new-input-c input {
    width: -webkit-fill-available;
  }

  .rit2-body {
    display: flex;
    justify-content: space-between;
  }

  .rit2-header {
    display: flex;
    justify-content: space-between;
  }

  .buyers-profile-box.bd-sec.documentCart {
    justify-content: space-between;
  }

  .documentCart .pdf-card {
    width: 48% !important;
    margin-bottom: 20px !important;
  }
}

@media screen and (max-width: 490px) {
  .rit-table-container {
    flex-direction: column;
  }

  .rit-left {
    width: 100%;
    margin-bottom: 20px;
  }

  .rit-right {
    width: 100%;
  }

  .rit-body {
    min-height: 60px;
  }

  .gt {
    font-size: 17px;
  }

  .rit3-table {
    width: 500px;
  }

  .rti3-parent {
    overflow-x: scroll;
  }
}

/* rk wallet css */
.widgets {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 0px;
}

.widgets p.type {
  font-size: 12px;
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.widgets p.type svg {
  font-size: 15px;
}

.widgets h6 {
  text-align: center;
  margin: 15px 0;
}

.widgets h6 svg {
  font-size: 35px;
}

.widgets h1 {
  text-align: center;
  font-size: 30px;
}

.widgets span {
  text-align: center;
  display: block;
  font-size: 12px;
  border-radius: 33.9219px;
  padding: 10px;
  width: 60%;
  margin: 0 auto;
}

.widgets a {
  font-size: 13px;
  text-align: center;
  display: block;
  text-decoration: underline !important;
  padding-top: 15px;
}

.widgets .wallet1 {
  background: #e5f0fe;
  border-radius: 10.9232px;
  padding: 15px;
  color: #042d5f;
}

.widgets .wallet1 span {
  background: #cbe3ff;
}

.widgets .wallet2 {
  background: #feedde;
  border-radius: 10.9232px;
  padding: 15px;
  color: #cd6506;
}

.widgets .wallet2 span {
  background: #ffdcbd;
}

.widgets .wallet3 {
  background: #fff8e0;
  border-radius: 10.9232px;
  padding: 15px;
  color: #a88301;
}

.widgets .wallet3 span {
  background: #fbe9ac;
}

.widgets .wallet4 {
  background: #ecffe6;
  border-radius: 10.9232px;
  padding: 15px;
  color: #269400;
}

.widgets .wallet4 span {
  background: #c6ffb2;
}

.rkwallet .table-parent {
  overflow: unset;
}

.rkwallet .table-parent .rit3-header82 div {
  font-weight: 600;
  font-size: 18px;
}

.rkwallet .table-parent .rit3-row82 {
  background-color: unset;
  border: unset;
}

.rkwallet .table-parent .rit3-row82 > div:last-child {
  text-decoration: unset;
}

.rkwallet .table-parent .rit3-row82 > div:last-child svg {
  font-size: 15px;
  color: #ccc;
}

.rkwallet .table-parent .rit3-row82 span.credit {
  color: #32a609;
}

.rkwallet .table-parent .rit3-row82 span.debit {
  color: #f00f0f;
}

#wallet-table .table tr td {
  padding: 8px 2px;
}
#wallet-table .table tr:nth-child(odd) {
  background-color: #ebebeb;
}

.rkwallet .searchPendingItem {
  display: flex;
  gap: 20px;
}

.rkwallet .searchPendingItem span {
  background: #006ff2;
  border-radius: 5px;
  color: #fff;
  padding: 7px 20px;
  cursor: pointer;
}

.rkwallet .flex-box {
  display: flex;
  justify-content: space-between;
}

.rkwallet .flex-box .form-control {
  width: 100%;
  position: relative;
}

.rkwallet .flex-box .form-select {
  padding: 10px;
  width: 200px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: #f9f9f9;
}

.rkwallet .flex-box .form-control svg {
  position: absolute;
  left: 19%;
  top: 15%;
  color: #33363f;
}

.rkwallet .flex-box button {
  background: #042d5f;
  color: #fff;
  padding: 10px 30px;
  width: 200px;
  border-radius: 8px;
  height: 40px;
  border: #042d5f;
}

.pending .tab {
  padding: 14px 30px;
}

.pending .rit3-row {
  display: flex;
  padding: 14px 0;
  width: 100%;
}

.pending .css-uvvswg-MuiButtonBase-root-MuiAccordionSummary-root {
  background: #ecf4fe !important;
  border-radius: 8px !important;
}

.pending .MuiPaper-root.MuiPaper-elevation {
  border-radius: 8px;
  margin-bottom: 20px;
}

.pending .MuiAccordionDetails-root {
  border-top: unset !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 8px;
}

.pending .css-uvvswg-MuiButtonBase-root-MuiAccordionSummary-root svg {
  color: #33363f;
}

.pending .MuiPaper-root .rit3-info {
  font-size: 15px;
}

.pending .MuiPaper-root .rit3-info.view {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  text-decoration: underline;
  color: #006ff2;
  cursor: pointer;
}

.pending .MuiPaper-root .platinum {
  background: rgba(242, 242, 242, 0.98);
  padding: 7px;
  border-radius: 20px;
  text-align: center;
  margin-right: 30px;
}

.pending .MuiPaper-root .gold {
  background: rgba(255, 215, 156, 0.98);
  padding: 7px;
  border-radius: 20px;
  text-align: center;
  margin-right: 30px;
}

.pending .MuiPaper-root .silver {
  background: #006ff2;
  padding: 7px;
  color: #fff;
  border-radius: 20px;
  text-align: center;
  margin-right: 30px;
}

.pending .MuiPaper-root .blue {
  background: #044b9f;
  padding: 7px;
  color: #fff;
  border-radius: 20px;
  text-align: center;
  margin-right: 30px;
}

@media screen and (max-width: 767px) {
  .welcome_page .box-display {
    display: block !important;
  }

  .welcome_page .box-display .leftwel {
    width: 100% !important;
  }

  .welcome_page .box-display .rightwel {
    width: 100% !important;
    padding: 30px !important;
  }

  .welcome_page .swiper-slide .graph {
    padding: 20px 10px !important;
  }

  .welcome_page .swiper-slide .bottomslide {
    top: 45% !important;
  }

  .welcome_page .swiper-slide .topslide p {
    font-size: 0.8rem !important;
  }

  .pending .tab {
    justify-content: space-around;
    gap: 0px;
  }

  .pending .tab .rit3-info {
    font-size: 14px;
  }

  .pending .rit3-row {
    justify-content: space-between;
  }

  .pending .MuiPaper-root .rit3-info.view {
    width: unset !important;
  }

  .pending .MuiPaper-root .rit3-info {
    font-size: 13px !important;
  }

  .pending .MuiPaper-root .platinum {
    height: 30px;
    width: 100px;
    margin-right: 0 !important;
  }

  .pending .MuiPaper-root .silver {
    height: 30px;
    width: 100px;
    margin-right: 0 !important;
  }

  .pending .MuiPaper-root .gold {
    height: 30px;
    width: 100px;
    margin-right: 0 !important;
  }

  .pending .MuiPaper-root .blue {
    height: 30px;
    width: 100px;
    margin-right: 0 !important;
  }
}
