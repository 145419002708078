.nav-sidebar {
  /* display: none !important; */
  position: fixed;
  top: 0;
  left: -308px;
  background: #f7f9fd;
  width: 268px;
  padding: 20px;
  height: 100%;
  box-shadow: 0px 4px 7px rgb(0 0 0 / 12%);
  transition: left 0.3s ease;
  border-radius: 0 10px 10px 0;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded{
   /* left: 92% !important; */
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded ul{
   padding-left:10px;
   padding-right:10px;
}


.nav-sidebar-active {
  left: 0;
}

.nav-sidebar-header .nav-brand {
  font-size: 19px;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
}

img.rklogo-sidebar {
  width: 23px;
  margin-bottom: 9px;
}

.nav-sidebar-body {
}

.nsb-heading {
  font-size: 19px;
  font-weight: 500;
  margin-top: 12px;
}

.line-nsb {
  width: 100%;
  height: 0.2px;
  margin: 10px 0 20px;
  border: 0.1px solid #dedbdb;
  display: block;
}

.nav-sidebar-body {
  display: flex;
  padding-top: 20px;
  flex-direction: column;
  height: 85%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.nsb-icon {
  margin-right: 4px;
  color: #006ff2;
}
.sidebar-spl-btn {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-bottom: 5px;
  padding: 10px 10px;
  color: black;
  border-radius: 10px;
}
.active-btn.sidebar-spl-btn {
  background: #006ff2;
  color: white !important;
}
.active-btn.sidebar-spl-btn .nsb-icon {
  color: white !important;
}


.sidebar-btn {
  position: absolute;
  bottom: 241px;
  left: 16px;
}