/* .regi-form-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.regi-form-container {
  width: 100%;
  /* height: 89vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 71px 0 50px;
}

.regi-form-container-signup {
  width: 100%;
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.regi-form-box {
  width: 700px;
  display: flex;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 0 6px -2px black;
  justify-content: space-between;
}

/* .regi-form-right {
    width: 46%;
    padding: 30px 15px;
} */
.regi-form-right {
  width: 50%;
  padding: 30px 15px;
}

.regi-form-right form {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* for popup */
  justify-content: space-between;
  height: 100%;
}

/* POPUP-FORM */

.regi-form-right form>div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.regi-form-right form h1 {
  margin: 0;
  font-size: 25px;
}

.regi-form-right form p {
  margin: 10px 0 50px;
  font-size: 12px;
  text-align: center;
}

.regi-form-right form .regi-input-c {
  width: 100%;
  margin-bottom: 20px;
}

.regi-form-right form .regi-input-c input {
  width: 100%;
  height: 45px;
  font-size: 15px;
  border-radius: 12px;
  background: #f4f2f2;
  border: none;
  padding: 6px 10px 6px 10px;
  outline: none;
}

.blue-txt {
  font-size: 12px;
  text-align: end;
  margin-top: 5px;
  font-weight: 500;
  color: #006ae8;
  cursor: pointer;
}

.regi-form-right form .button {
  width: 100%;
  height: 41px;
  margin-top: 50px;
  border: none;
  background: #006ae8;
  border-radius: 12px;
  font-size: 17px;
  font-weight: 600;
  color: white;
  padding: 6px 0px;
  cursor: pointer;
  text-decoration: none;
  display: grid;
  justify-content: center;
  align-items: center;
}

.bottom-txt {
  font-size: 12px;
  font-weight: 500;
  margin: 10px 0 0px;

}

.regi-input-c select {
  width: 100%;
  height: 39px;
  font-size: 15px;
  border-radius: 12px;
  background: #f4f2f2;
  border: none;
  padding: 6px 10px 6px 10px;
  outline: none;
  color: #757589;
}

.otp-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.otp-resend-root {
  flex-direction: column !important;
  margin-top: 20px;
}

.otp-box span {
  margin: 0;
  display: flex;
  align-items: center;
  width: 250px;
  height: 53px;
  justify-content: center;
}

.otp-box button {
  background: transparent !important;
  color: #006ae8 !important;
  font-size: 12px !important;
  margin-top: 10px !important;
}

.otp-box input:last-child {
  margin-right: 0 !important;
}

@media screen and (max-width: 750px) {
  .regi-form-container {
    height: auto;
    padding: 50px 0;
  }

  .regi-form-box {
    width: 350px;
    flex-direction: column;
  }

  .regi-form-left {
    width: 100% !important;
  }

  .regi-form-right {
    width: 90%;
    padding: 30px 15px;
  }
}


.account-form {
  width: 40%;
  text-align: center;
}

.af-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
}

.input-container-af {
  width: 48%;
  margin-bottom: 20px;
}

.input-container-af input {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  padding-left: 6px;
  border: none;
  background: #f3f3f3;
  outline: none;
}

.account-form .afb {
  margin-top: 30px;
  width: 100%;
  height: 35px;
}

@media screen and (max-width:620px) {
  .account-form {
    width: 90%;
  }

  .input-container-af {
    width: 100%;
    margin-bottom: 13px;
  }

  .input-container-af input {
    width: 90%;
  }
}


.otp-box.hehe-otp input[type=tel] {
  width: 40px !important;
  height: 40px !important;
  border-radius: 5px !important;
}







/* welcome css */
/* .welcome_page .container{
  padding: 0px 50px;
} */

.welcome_page .box-display {
  display: flex;
  gap: 30px;
}

.welcome_page .box-display .leftwel {
  padding: 50px;
  width: 50%;
}

.welcome_page .box-display .leftwel .form-data {
  justify-content: space-between;
  gap: 30px;
}

.welcome_page .box-display .leftwel .form-control {
  position: relative;
  width: 100%;
}

.welcome_page .box-display .leftwel .form-control svg {
  position: absolute;
  right: 2%;
  font-size: 13px;
  top: 46%;
}

.welcome_page .box-display .leftwel p {
  font-size: 0.8rem;
  color: #000;
  padding-bottom: 30px;
}

.welcome_page .box-display .leftwel label {
  display: inherit;
  font-size: 15px;
  padding-bottom: 15px;
}

.welcome_page .box-display .leftwel input {
  padding: 10px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #D9D9D9;
}

.welcome_page .box-display .leftwel small {
  text-align: right;
  display: inherit;
  color: #006FF2;
  text-decoration: underline;
  font-size: 12px;
  padding-top: 10px;
}

.welcome_page .box-display .leftwel .account {
  background: #006FF2;
  border: #006FF2;
  color: #fff;
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  margin: 40px 0;
  font-size: 15px;
}

.welcome_page .box-display .leftwel .sign {
  font-size: 15px;
  display: block;
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
  color: #006FF2;
}

.welcome_page .box-display .leftwel .google {
  background: #fff;
  border: 1px solid #D9D9D9;
  color: #686767;
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  font-size: 15px;
}

.welcome_page .box-display .leftwel .dont {
  font-size: 15px;
  display: block;
  width: 100%;
  text-align: center;
  margin: 30px 0 0 0;
  text-decoration: underline !important;
}


.welcome_page .box-display .rightwel {
  background: #006FF2;
  padding: 50px;
  width: 50%;
}

.welcome_page .box-display .rightwel h1 {
  font-size: 2rem;
  color: white;
  margin: 10px 0 5px;
  width: 250px;
}

.welcome_page .box-display .rightwel p {
  font-size: 0.8rem;
  color: white;
  padding-bottom: 30px;
}

.welcome_page .swiper {
  padding: 50px 0 !important;
}

.welcome_page .swiper .swiper-pagination-bullet {
  background-color: #fff;
  opacity: 1;
}

.welcome_page .swiper .swiper-pagination-bullet-active {
  width: 50px;
  height: 9px;
  background: #fff;
  border-radius: 16px;
}

.welcome_page .swiper-slide {
  display: unset !important;
  position: relative;
}

.welcome_page .swiper-slide .topslide {
  position: absolute;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
  top: -7%;
  right: 0%;
  left: 22%;
  gap: 20px;
}

.welcome_page .swiper-slide .topslide span {
  font-size: 14px;
}

.welcome_page .swiper-slide .topslide img {
  width: 100px;
}

.welcome_page .swiper-slide .topslide p {
  font-size: 1.0rem !important;
}

.welcome_page .swiper-slide .bottomslide {
  position: absolute;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 20px 40px;
  top: 30%;
  right: 0%;
  left: 22%;
  height: 200px;
  /* width: 100%; */
}

.welcome_page .swiper-slide .graph {
  background: #ECF5FF;
  border-radius: 10px;
  padding: 20px;
  /* width: 70%; */
}

.welcome_page .swiper-slide .graph h2 {
  font-size: 60px;
  margin: 10px 0 40px;
  color: #042D5F;
}

.welcome_page .swiper-slide .graph h2 small {
  color: #269400;
  font-size: 15px;
}

.welcome_page .swiper-slide .graph p {
  font-weight: 600;
  font-size: 15px;
  color: #042D5F;
  padding: 0;
}

.welcome_page .swiper-slide .recharts-responsive-container {
  height: 400px !important;
}

.welcome_page .swiper-slide .recharts-responsive-container text.recharts-text {
  stroke: #006FF2 !important;
}


/* profile css */
.profile_page {
  width: 70%;
  margin: 50px auto;
  display: block;
}

.profile_page h1 {
  font-size: 1.5em;
  text-align: center;
}

.profile_page p {
  text-align: center;
}

.profile_page ul.summary {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 20px 40px;
}

.profile_page ul.summary li {
  list-style: none;
  border-bottom: 10px solid #D9D9D9;
  width: 100%;
  color: #D9D9D9;
  font-size: 15px;
  padding-bottom: 10px;
}

.profile_page ul.summary li:first-child {
  list-style: none;
  border-bottom: 10px solid #006FF2;
  width: 100%;
  color: #006FF2;
}

.profile_page ul.summary.step2 li:nth-child(2) {
  list-style: none;
  border-bottom: 10px solid #006FF2;
  width: 100%;
  color: #006FF2;
}

.profile_page ul.summary.step3 li:nth-child(3) {
  list-style: none;
  border-bottom: 10px solid #006FF2;
  width: 100%;
  color: #006FF2;
}

.profile_page .form-control {
  width: 100%;
  position: relative;
}

.profile_page .form-control svg {
  position: absolute;
  right: 1%;
  top: 61%;
  color: #9B9B9B;
  font-size: 19px;
}

.profile_page .d-flex {
  gap: 30px;
  align-items: flex-end;
}

.profile_page .form-select {
  padding: 10px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #D9D9D9;
  color: #ccc;
}

.profile_page .form-select option {
  color: #000;
}

.profile_page .form-control label {
  color: #7B7B7B;
  display: block;
  font-size: 15px;
  padding-bottom: 15px;
}

.profile_page .form-control input {
  padding: 10px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #D9D9D9;
}

.profile_page .form-control button.cancel {
  background: #fff;
  border: #fff;
  color: #006FF2;
  padding: 10px;
  width: 50%;
}

.profile_page .form-control button.continue {
  background: #006FF2;
  border: 1px solid #006FF2;
  border-radius: 8px;
  color: #fff;
  padding: 10px 20px;
  width: 50%;
}

.profile_page .form-control button.complete {
  background: #39DB00;
  border: 1px solid #39DB00;
  border-radius: 8px;
  color: #fff;
  padding: 10px 20px;
  width: 50%;
}

.profile_page .form-control small {
  position: absolute;
  top: 60%;
  right: 4%;
  font-size: 12px;
  color: #006FF2;
}

.profile_page h6 {
  color: #7B7B7B;
  font-size: 16px;
  font-weight: normal;
  margin: 0;
}

.profile_page p.desc {
  text-align: left !important;
  font-size: 15px;

}

.profile_page form {
  border: 1px solid #D9D9D9;
  padding: 30px;
  border-radius: 10px;
}

.profile_page .doc-type {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.profile_page .doc-type p {
  color: #7B7B7B;
  width: 400px;
}

.profile_page .doc-type p span {
  color: #006FF2;
  font-size: 20px;
}

.profile_page label.uplaod {
  background: #042D5F;
  color: #fff;
  border: #042D5F;
  padding: 15px 20px;
  width: fit-content;
  display: flex;
  gap: 5px;
}

.profile_page label.uplaod input{
  /* position: absolute; */
  /* z-index: -1; */
  /* opacity: 0; */
}

.profile_page label.uplaod svg {
  font-size: 15px;
}




@media screen and (max-width: 767px) {
  .profile_page {
    width: 90%;
    margin: 50px auto;
    display: block;
  }

  .profile_page ul.summary {
    padding: 0px 0px 20px;
  }

  .profile_page .d-flex {
    display: block !important;
  }

  .profile_page .form-control {
    width: 100% !important;
  }

  .profile_page .doc-type {
    display: block;
  }

  .profile_page .doc-type p {
    width: unset;
  }

}