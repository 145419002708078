.po-h1 {
  width: 85.7%;
  margin-left: auto;
  margin-top: 50px;
}
.place-order-container {
  display: flex;
  width: 85%;
  margin: auto;
  padding-bottom: 50px;
}
.place-order-left {
  width: 60%;
  background: #fff;
  box-shadow: 0 12px 20px -16px #aaa;
  padding: 10px 15px;
  border-radius: 3px;
  height: fit-content;
}

.place-order-left .seller-info{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.place-order-left .seller-info p, .place-order-left small{
  font-weight: normal;
  color: #686767;
}

.delivery_type_div{
  margin-top: 50px;
}

.place-order-left .seller-info p.seller_name{
  font-weight: bold;
  color: #000;
}

.pol-header {
  display: flex;
  flex-direction: column;
  background: #f4f4f4;
  border-radius: 10px 10px 0 0;
}
.pol-header .pol-main-header {
  display: flex;
  justify-content: space-between;
  padding: 20px 35px;
  align-items: center;
}
.big-bold-heading {
  font-weight: 500;
}
.pol-header-sibling {
  padding: 10px 37px 30px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  height: 120px;
  transition: height 0.2s ease;
}
.pol-header-sibling-child {
  display: flex;
  flex-direction: column;
}
.bold-heading {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 20px;
}

.pol-header-sibling-child p {
  margin: 0;
  font-size: 12px;
  width: 160px;
  font-weight: 400;
  color: #827e7e;
}
.pol-header-sibling-child input {
  border: none;
  height: 22px;
  border-radius: 5px;
  outline: none;
  padding: 4px 5px;
}

.pol-bottom {
  background: #ffffff;
  /* box-shadow: 0px 0px 10px rgb(0 0 0 / 8%); */
  border: 1px solid #c7c7c7;
  border-radius: 10px;
  padding-top: 15px;
  margin-top: 0px !important;
}

.blue-msg {
  padding-left: 15px;
  font-size: 12px;
  font-weight: 600;
  color: #006ff2;
}


span.platinum,
span.gold,
span.silver,
span.premium.blue,
span.paid.blue,
span.blue {
  padding: 7px 20px;
  border-radius: 20px;
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  /* margin-right: 30px; */
  display: block;
  text-align: center;
}

span.platinum {
  background-color: #f3f3f3;
  color: #000;
}
span.gold {
  background-color: #ffd79cfa;
  color: #000;
}
span.silver {
  background-color: #cac9c7fa;
  color: #000;
}
span.premium.blue {
  background-color: #5ea8ff;
  color: #fff;
}
span.paid.blue {
  background-color: #006ff2;
  color: #fff;
}
span.blue {
  background-color: #044b9f;
  color: #fff;
}

.cart-help-p p {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  color: #827e7e;
  margin-bottom: 0px;
}

.cart-no {
  font-size: 12px;
  margin-bottom: 25px;
}

.cart-inpu-box {
  margin-bottom: 20px;
}
.cart-inpu-box input {
  border: none;
  border-radius: 9px;
  height: 32px;
  background: #f4f4f4;
  padding: 5px 10px;
  outline: none;
}

.place-order-right {
  width: 38%;
  background-color: #fff;
  margin: 0 20px;
  padding: 0px 0 0;
  /* background: #f4f4f4; */
  /* border-radius: 10px; */
  height: fit-content;
}

.payment-summary-box {
  padding: 20px 15px 0;
  /* background: #f4f4f4; */
  border-radius: 10px;
  border: 1px solid #c7c7c7;
}
.payment-summary-box .bold-heading {
  font-weight: 400;
}
.blue-box {
  margin: 20px 0 0;
  /* background: #c6eeff; */
  border-radius: 10px;
  /* padding: 18px 17px 0; */
  /* border: 1px solid   #c7c7c7; */
}

.backbtn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 100%;
  height: 35px;
  font-weight: 600;
  border-radius: 7px;
  border: 1px solid #006ff2;
  font-size: 15px;
  /* margin-bottom: 10px; */
  margin-top: 10px;
  background: white;
  color: #006ff2;
  cursor: pointer;
}
.contbtn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 100%;
  height: 35px;
  font-weight: 600;
  border-radius: 7px;
  border: 1px solid #006ff2;
  font-size: 15px;
  margin-top: 10px;
  background: #006ff2;
  color: white;
  cursor: pointer;
}

.fold {
  height: 0 !important;
  z-index: -1;
  padding: 0 !important;
  overflow: hidden;
}

.popupbox {
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: black;
  width: 100%;
  z-index: 9999;
  opacity: 0.8;
  height: 100vh;
  display: none;
}
.popup-content {
  position: fixed;
  top: 50%;
  width: 430px;
  display: none;
  margin: auto;
  z-index: 9999999;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation-name: example;
  animation-duration: 0.4s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  flex-direction: column;
}

.popup-content > div {
  background-color: white;
  padding: 20px;
  border-radius: 7px;
  margin-bottom: 20px;
  /* height: 287px; */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  overflow-y: scroll;
}

.popup-content div::-webkit-scrollbar {
  display: none;
}

svg.popup-i {
  font-size: 23px;
  position: absolute;
  top: 19px;
  right: 17px;
  cursor: pointer;
}

.active-popup .popup-overlay {
  display: block;
}
.active-popup .popup-content {
  display: inline-flex;
}

.popup-content h1 {
  margin: 0 0 15px;
  font-size: 19px;
  color: #006ff2;
  font-weight: 400;
}

.pc-cart-r {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  font-weight: 500;
}

.input-heading-container {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 5px;
}

.input-heading {
  width: 30%;
  text-align: center;
  color: #868282;
  font-size: 15px;
  font-weight: 400;
}

.input-rows {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 10px 0;
}

input.ir1 {
  width: 30%;
  text-align: center;
  font-weight: 500;
  border-radius: 5px;
  height: 31px;
  border: none;
  background: #ebebeb;
  outline: none;
}

.c-p-i-b {
  margin-top: 20px;
  width: 120px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  border-radius: 7px;
  font-weight: 600;
  font-style: initial;
  color: white;
  cursor: pointer;
  background: #006ff2;
}

.cart-milestone-box {
  padding: 20px;
  background: white;
  margin-top: 22px;
  border-radius: 9px;
  /* box-shadow: 0px 0px 10px rgb(0 0 0 / 8%); */
  border: 1px solid #c7c7c7;
}

.cart-milestone-box h1 {
  margin: 0 0 15px;
  font-size: 19px;
  color: #006ff2;
}

.ir1-1 {
  width: 30%;
  text-align: center;
  font-weight: 500;
  border-radius: 5px;
  height: 31px;
  border: none;
  outline: none;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes example {
  from {
    opacity: 0;
    top: 80%;
  }
  to {
    opacity: 1;
    top: 50%;
  }
}

@media screen and (max-width: 1000px) {
  .place-order-container {
    flex-direction: column;
  }
  .place-order-left {
    width: 100%;
  }
  .place-order-right {
    width: 100%;
    padding: 20px 0 0;
    margin: 0;
  }
  .pol-header-sibling {
    /* height: 205px; */
    height: auto;
  }
}

@media screen and (max-width: 600px) {
  .pol-header-sibling {
    flex-direction: column;
  }
  .pol-header-sibling-child {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  .pol-header-sibling-child:last-child {
    margin-bottom: 0px;
  }

  .place-order-container {
    width: 90%;
  }
  .po-h1 {
    width: 93.7%;
  }
  .pol-bottom {
    margin-top: 0 !important;
  }
}

@media screen and (max-width: 460px) {
  .popup-content {
    width: 93%;
  }
}
.bold-heading88 {
  font-weight: 400 !important;
}
.product_info82 {
  border: 1px solid #c7c7c7;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
}
.cardProImg {
  /* height: 102px;
  width: 80px; */
  /* float: left; */
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardProInfo {
  float: left;
  margin-left: 15px;
  width: auto;
}
.cartCardItem {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}
.cartCardOuter {
  display: flex;
  box-sizing: border-box;
  padding: 15px;
}
.cardProImg img {
  max-width: 80px;
    max-height: 100px;
}
.cardProInfo p {
  margin: 0px;
}
.cartCardItem {
  margin-top: 15px;
  font-size: 15px;
  color: #686767;
}

@media screen and (max-width: 550px) {
  .emer-cart-op {
    flex-direction: column;
  }
  .emer-cart-box {
    margin-bottom: 20px;
  }
  .emer-cart-box:last-child {
    margin-bottom: 0px;
  }
  .emer-cart-box > div {
    margin-bottom: 5px;
  }
  .emer-cart-box p {
    font-size: 12px;
  }
}

.deliver_pickup-btns {
  display: flex !important;
  justify-content: space-between !important;
  margin-bottom: 25px;
}
.deliver_pickup-btns button {
  width: 234px;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-size: 14px;
}

.dpco-con {
  padding: 0 37px;
}

@media screen and (max-width: 770px) {
  .deliver_pickup-btns {
    display: flex !important;
    flex-direction: column !important;
    justify-content: unset;
    margin-bottom: 25px;
  }
  .deliver_pickup-btns button {
    width: 100%;
    margin-bottom: 10px;
  }
}

.edit-address-popup-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 22px;
}

.inner-edit-add-box {
  justify-content: space-between;
}

.inner-edit-add-box .edit-address-popup-div {
  width: 48%;
}

.inner-edit-add-box:last-child .edit-address-popup-div {
  margin-bottom: 0;
}

.edit-address-popup-div input {
  height: 37px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid #d5d5d5;
  padding-left: 5px;
  line-height: 37px;
}
.edit-address-popup-div label {
  font-size: 14px;
}
