/* .main-section {
  height: 100vh;
} */

.divider {
  width: 100%;
  /* background: red; */
  display: flex;
  /* height: 89.2vh; */
}
.left-part {
  width: 17%;
  background: white;
  position: sticky;
  left: 0;
  z-index: 998;
  top: 81px;
  height: 100%;
}
.right-part {
  width: 83%;
  /* background: bisque; */
}

/* .shop-card-container {
  display: flex;
  padding: 15px;
  justify-content: space-between;
  flex-wrap: wrap;
} */

.shop-card-container {
  padding: 15px;
  --auto-grid-min-size: 16rem;
  display: grid;
  grid-template-columns: repeat( auto-fill, minmax(var(--auto-grid-min-size), 1fr) );
  grid-gap: 1rem;
  justify-items: center;
}

.active-sidebar .callbtn2-i{
  transform: rotate(133deg);
  transition: transform 0.3s ease-in-out;
}

.callbtn2 {
  position: absolute;
  top: 78px;
  right: -40px;
  border-radius: 0 10px 10px 0;
  border: 2px solid #006FF2;
  color: #006FF2;
  background: transparent;
  display: none;
}

.clr-btn {
  position: absolute;
  right: 15px;
  top: 16px;
}

@media screen and (max-width: 1000px) {

  .callbtn2{
    display: block;
  }

  .left-part {
    left: -100%;
    width: 100%;
    background: white;
    position: fixed;
    z-index: 99;
    box-shadow: 0px 4px 7px rgb(0 0 0 / 12%);
    height: 100%;
    transition: 0.3s ease;
    top: 64px;
}

  .right-part {
    width: 100%;
  }

  .shop-card-container {
    justify-content: space-around;
    /* padding: 0 ; */
  }
}

@media screen and (max-width: 700px) {
 

  .product-card {
    
  }
}


.filter-close{
  display: none !important;
}
@media screen and (max-width:1000px){
.navfilters{
  display:  none !important;
}
.filter-close{
  display: block !important;
}
}

