.checkout-container {
    /* width: 42%; */
    margin: 70px auto;
    display: block;
    width: 530px;
}

.checkout-container h1{
    font-size: 20px;
    margin-bottom: 67px;
}

.checkout-radio-box {
    margin: 20px 0;
    width: 78%;
    margin-left: auto;
}

.checkout-radio-box>div {
    margin-bottom: 12px;
    font-size: 12px;
    font-weight: 500;
}

.checkout-radio-box label{
margin-bottom: -5px;
}

/* .checkout-radio-box>div input {
    margin-top: 0;
    margin-right: 7px;
} */

.checkout-main-box {
    background: #F0F0F0;
    padding: 25px;
    border-radius: 8px;
}
.checkout-top-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;
}
.checkout-top-box img {
    width: auto;
    height: 70px;
}
.checkout-top-box ul {
    list-style: none;
    padding: 0px;
    font-size: 14px;
    margin-top: 0;
}
.checkout-top-box ul li {
    font-weight: 400;
    margin-bottom: 4px;
}


.checkout-bottom-box {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.checkout-bottom-box img{
    height: 104px;
}

/* .checkout-bottom-btns{} */
.checkout-bottom-btns .cbb {
    margin-left: 10px;
    height: 30px;
    width: 134px;
    border-radius: 6px;
    font-weight: 600;
    color: white;
    border: 2px solid #006FF2;
    cursor: pointer;
    background: #006FF2;
    font-family: inherit;
    text-decoration: none;
    display: inline-grid;
    align-items: center;
    justify-content: center;
    font-size: 13px;
}


.checkout-form {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.checkout-form input {
    width: 75%;
    background-color: #f0f0f0;
    border-radius: 7px;
    padding: 11px;
    border: none;
    outline: none;
}
.checkout-form label ,
.checkout-form button {
    width: 20%;
    text-align: center;
    background: #006ff2;
    color: white;
    font-weight: 500;
    border-radius: 7px;
    padding: 10px 0;
    font-size: 13px;
    outline: none;
    border: none;
    font-family: inherit;
}

.checkout-form input::-webkit-file-upload-button{
    visibility: hidden;
    display: none;
}


@media screen and (max-width:600px){
    .checkout-container {
                width: 90%;
                margin: 30px auto;
    }
    .checkout-top-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        flex-direction: column;
        text-align: center;
    }
    .checkout-top-box img{
        margin-bottom: 20px;
    }
    .checkout-bottom-box {
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .checkout-bottom-btns .cbb {
        width: 127px;
        margin-top: 20px;
    }

    .checkout-form input {
        width: 70%;
    }
}


/* Newcheckout css */

.checkout-container {
    width: inherit;
    padding: 30px;
    display: flex;
    margin: 0 0;
    justify-content: center;
}
.checkout-container-wraper{
    /* max-width: 1100px; */
    width: 80%;
    padding: 30px;
    display: flex;
    margin: 0 0;
}
.checkout_heading{
    padding-left: 30px;
    margin-bottom: 0;
}
.checkout-container .lt-container {
    width: 80%;
    border-right: 2px solid #B6B6B6;
    padding: 20px 20px 20px 0;
}
.checkout-container .rt-container {
    width: 35%;
    padding: 20px 0 20px 20px;
}

.d_flex{
    width: inherit;
    display: flex !important;
    justify-content: space-between;
}
.mb-4{
    margin-bottom: 40px;
}
.mb_4{
    margin-bottom: 40px !important;
}
.mt-4{
    margin-top: 40px;
    margin-bottom: 40px;
}
.mt-1{
    margin-top: 10px !important;
}
.d-flex{
    display: flex !important;
}
.flex_row{
    flex-direction: row !important;
}
.ms-auto{
    margin-left: auto;
}
.text-end{
    text-align: end;
}
.mt-0{
    margin-top: 0 !important;
}
.checkout-btn{
    /* width: 300px; */
        width: 293px;
    text-align: start;
    padding: 8px 10px;
    border: 1px solid #7C7C7C;
    border-radius: 10px;
    background:  #fff;;
}
.checkout-btn:hover{
    background: #EEF6FF;

}
.checkout-btn:focus{
    background: #EEF6FF;

}
.amount{
    margin-bottom: 10px;
    color: #38D900;
    font-weight: 600;
    font-size: 28px;
    line-height: 30px;
    margin-top: 10px;

}
.text-gray{
    color: #989898;
}
.instr-list{
    list-style: decimal;
}
.instr-list li span{
    color:#006FF2;
    text-decoration: underline;
}
.instr-list li {
    margin-bottom: 20px;
}
.pay-btn{
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    border: none;
    font-size: 22px;
    font-weight: 500;
    color: white;
    background: #006FF2;
    border-radius: 5px;
    
}
.css-j204z7-MuiFormControlLabel-root{     margin-left: 0% !important;}
.product_card{
    /* width: 450px; */
    width: 100%;
    border-radius: 10px;
    padding: 0 15px 10px;
    border: 1px solid #C6C6C6;
    margin-bottom: 40px;
}
.checkout_product_img_container{
    padding: 10px;
}
.checkout_product_img{
    object-fit: cover;
    width: 100px;
    height: 116px;
    border: 1px solid #C6C6C6;
}
.checkout_product_img img{
    width: 100px;
    height: 116px;
}
.checkout_product_card_heading{
    margin: 20px 0;
}
.checkout_product_details{
    padding: 0 10px;
}
.checkout_product_details h3, .checkout_product_details p{
    color: #686767;
    
}
.checkout_product_details h3{
    margin: 20px 0;
}
.checkout_product_details p{
    margin-top: 0;
    margin-bottom: 20px;
}

.addresses{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
}

.address_box{
    border: 2px solid #686767;
    border-radius: 4px;
    font-size: 14px;
    width: 45%;
    margin-right: 20px;
    color: #686767;
    padding: 20px 10px;
}
.address_box .form-label{
    font-size: 12px;
}
.price_card{
    /* width: 450px; */
    width: 100%;
    border-radius: 10px;
    padding: 0 15px 10px;
    border: 1px solid #C6C6C6;
}
.price_card .price_details{
    display: flex;
    justify-content: space-between;
}
.price_card .price_details p{
    margin-top: 0;
    margin-bottom: 20px;
}


@media screen and (max-width:1200px) {
    .css-ahj2mt-MuiTypography-root{
        font-size: 12px !important;
    }
    .checkout-container-wraper{
        flex-wrap: wrap;
        max-width: 540px;
        padding: 0;
    }
    .checkout-btn{
        width: 250px;
    }
    
    .checkout-container .lt-container {
        width: 100%;
        order: 2;
        border-right: none;
        padding: 0px 0px 0px 0;
        
    }
    .checkout-container .rt-container {
        width: 100%;
        order: 1;
        margin-bottom: 50px;
        padding: 0px 0 0px 0px;
        display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

        
    }
    
}
@media screen and (max-width:1060px) {
    .checkout-container{
        flex-wrap: wrap;
    }
    .product_card{
        margin-bottom: 40px;
    }
    .checkout-container .lt-container {
        order: 2;
        width: 100%;
        border-right: none;
        padding: 0px 0px 0px 0;
        margin-bottom: 50px;
    }
    .checkout-container .rt-container {
        width: 100%;
        order: 1;
        padding: 0px 0 0px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        
    }
}
@media screen and (max-width:650px) {
    .checkout-btn{
        width: 300px;
    }
    .d_flex {
        gap: 30px;
        justify-content: center;
    }
    .product_card{
        width: 300px;
        padding: 0 10px 10px;
        margin-bottom: 40px;
    }
    .checkout_product_img_container{
        padding: 10px;
    }
    .checkout_product_img{
        object-fit: cover;
        width: 70px;
        height: 130px;
        border: 1px solid #C6C6C6;
    }
    .checkout_product_img img{
        width: 70px;
        height: 130px;
    }
    
    .checkout_product_details p{
        font-size: 15px;
    }
    .checkout_product_details {
        padding: 0 0px;
    }
    .price_card{
        width: 300px;
        padding: 0 10px 10px;
        margin-bottom: 40px;
    }
    .mb_4 {
        margin-bottom: 30px !important;
    }
    .price_card .price_details p {
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 18px;
    }
    .instr-list li {
        margin-bottom: 15px;
        font-size: 18px;
    }

    .amount{
        margin-bottom: 0;
        font-size: 15px;
    }

    .amt-container .text-gray{
        font-size: 11px;
    }
    .CheckOutPayNowBTN a {  
        font-size: 15px;
    }
    .CheckOutPayNowBTN {
        padding-top: 0px;
        padding-bottom: 0px;
        margin-top: 10px;
    }
    .instr-list li {
        margin-bottom: 15px;
        font-size: 14px;
    }
    .instr-list.InstructionsOnline{
        padding-left: 16px;
    }
}
.InstructionsTransfer{display: none;}