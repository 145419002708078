.filter-heading {
  margin: 15px 10px 15px 22px;
  font-size: 18px;
}

.accordian {
  margin: 10px;
}

.accordian-btn {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 14px;
  font-size: 15px;
  /* background-color: #006FF2; */
  background-color: #fff;
  color: #000;

  font-weight: 600;
  border: none;
  border-radius: 12px;
  justify-content: space-between;
}
.accordian-btn div {
  display: flex;
  align-items: center;
}

.btn-icons {
  margin-right: 5px !important;
}
.filterOterSection{border: 1px solid #c7c7c7;
  width: 92%;
  margin-left: 8%; border-radius: 6px;}
 .filterOterSection .accordian-data { border-bottom: 1px solid #c7c7c7;height: 174px;
  overflow-y: scroll;}
  
    
  .accordian-data2 {    overflow-y: unset !important;
  height: 63px !important;}
 .filterOterSection .accordian-form > span{padding: 0px !important;}
.accordian-data {
  margin-top: 13px;
  background: #fff;
  /* padding: 10px; */
  border-radius: 100px + calc(0);
  transition: height 0.2s ease;
  border-radius: 0px;
}
.css-nnid7-MuiSlider-valueLabel{ background-color: #006ff2 !important;}
.ad-con{
padding: 10px 0px;
height: max-content;
/* height: 174px; */
overflow: scroll;
}
.ad-con::-webkit-scrollbar {
    width: 2em;
    height: 2em;
    display: block !important;
    
}
html {

  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
}
.accordian-form {
  display: flex;
  align-items: center;
  /* height: 40px; */

  margin-bottom: 15px;
}
.accordian-form input {
}
.filterOterSection svg{    width: 17px;
  height: 17px;
margin-right: 5px;}
.accordian-form label {
  font-size: 13px;
  font-weight: 400;
}


.rotated{
  transform: rotate(180deg);
  transition: transform 0.4s ease !important;
}

@media screen and (max-width:1000px){
  .accordian-data {
    background: white;
  }

  .filter-heading {
    margin: 30px 10px 30px 22px;
    font-size: 18px;
}

.clr-btn {
  position: absolute;
  right: 15px;
  top: 29px;
}

}

@media screen and (max-width:550px){
  .filterOterSection {
    border: 1px solid #c7c7c7;
    width: 92%;
    margin-left: 0%;
    border-radius: 6px;
    margin-inline: auto;
}
}