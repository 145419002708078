.approve-div {
    padding: 20px;
    width: 600px;
    margin-inline: auto;
    border: 1px solid #c7c7c7;
    margin-top: 30px;
    border-radius: 10px;
    margin-bottom: 30px;
}

.approve-div strong {
    font-size: 22px;
    margin-bottom: 30px;
    display: block;
}

.approve-form {
    /* padding: 20px; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.approve-input-c {
    display: flex;
    width: 45%;
    flex-direction: column;
    margin-bottom: 36px;
}

.approve-input-c label {
    font-weight: 500;
}

.approve-input-c .label-2-g {
    font-size: 12px;
    font-weight: 400;
    color: grey;
}

.approve-input-c input {
    height: 34px;
    border-radius: 8px;
    outline: none;
    /* border: 0.9px solid black; */
    border: none;
    font-size: 15px;
    box-shadow: rgb(0 0 0 / 24%) 0px 1px 5px;
    margin-top: 10px;
    padding-left: 11px;
}


.approve-milestone {
    width: 100%;
}

.approve-milestone h1 {
    margin-top: 0;
}

.approve-milestone .input-heading {
    width: 30%;
    text-align: center;
    color: #0a75f3;
    font-size: 15px;
    margin-bottom: 20px;
    font-weight: 500;
}

.approve-milestone input.ir1 {
    width: 30%;
    text-align: center;
    font-weight: 500;
    border-radius: 5px;
    height: 31px;
    border: none;
    background: #ffffff;
    outline: none;
    margin-bottom: 20px;
    box-shadow: rgb(0 0 0 / 24%) 0px 1px 5px;
}

@media screen and (max-width:640px) {
    .approve-div {
        padding: 20px;
        width: auto;
        margin-inline: auto;
    }

    .approve-input-c {
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-bottom: 36px;
    }


}




/* order details css */
.oder-detail.profile_page .delivery-status-box {
    border-bottom: 1px solid #D9D9D9 !important;
    border: unset;
    border-radius: unset;
    padding: 20px 0;
}
.oder-detail.profile_page .bhdBorder{
    border-bottom: 1px solid #D9D9D9 !important;
    border: unset;
    border-radius: unset;
    padding: 20px 0;
}
.oder-detail.profile_page .docum{
    border-bottom: 1px solid #D9D9D9;
    border-radius: unset;
    padding: 30px 0;
}
.oder-detail.profile_page .form-control label{
    color: #000;
    font-size: 17px;
    font-weight: 600;
}
.oder-detail.profile_page .form-control svg.icon {
    position: absolute;
    right: -2%;
    top: 64%;
    transform: translate(-50%, -50%);
    background: #006FF2;
    border-radius: 0px 8px 8px 0px;
    color: #fff;
    font-size: 20px;
    padding: 11px;
    height: 50px;
    width: 50px;
}
.oder-detail.profile_page a.add {
    font-size: 15px;
    display: flex;
    align-items: center;
    color: #006FF2;
    gap: 10px;
}
.oder-detail.profile_page a.add svg{
    font-size: 15px;
}

.oder-detail.profile_page .address{
    display: flex;
    gap: 20px;
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 30px;
    gap: 50px;
}
.oder-detail.profile_page .address .shipp span{
    color: #7B7B7B;
    font-size: 14px;
}
.oder-detail.profile_page .address .accunt {
    display: flex;
    align-items: center;
    gap: 100px;
}

.oder-detail.profile_page .address .accunt span{
    color: #7B7B7B;
    font-size: 14px;
}
.oder-detail .border{
    border-bottom: 1px solid #D9D9D9;
}
.oder-detail.profile_page .account{
    display: flex;
    gap: 20px;
    padding-bottom: 30px;
}
.oder-detail.profile_page .account .detail {
    display: flex;
    width: 50%;
}
.oder-detail.profile_page .account .detail span{
    color: #7B7B7B;
}
.oder-detail.profile_page .account .detail label {
    width: 200px;
}
.oder-detail.profile_page .account .detail a {
    margin-left: 100px;
    color: #006FF2;
    text-decoration: underline !important;
    font-size: 15px;
}